import { alpha, Box } from "@mui/material";
import CustomDialog from "./Dialog";

function ConfirmationDialog({ open, closeDialog, width, children, ...props }) {
  return (
    <CustomDialog
      hasCloseButton={true}
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: {
            xxs: "340px",
            sm: width || "426px"
          }
        }
      }}
      setClose={closeDialog}
      open={open}
      {...props}>
      <Box sx={{ pt: { xxs: 0, sm: 2 }, px: { xxs: 0, sm: 4.5 } }}>{children}</Box>
    </CustomDialog>
  );
}

export default ConfirmationDialog;
