import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../config";
import axios from "../../utils/axios";

const BASE_URL = config.DMA_API_URL;

const initialState = {
  isLoading: false,
  failedTx: null,
  referrals: { total_page: 1, data: [] },
  rewards: { total_page: 1, transactions: [] },
  transfers: { total_page: 1, transactions: [] }
};

export const slice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPage(state, action) {
      const { type, page } = action.payload;
      state[type] = { ...state[type], page: page };
    },
    getReferralsSuccess(state, action) {
      state.isLoading = false;
      const { referrals: data, total_page } = action.payload;
      state.referrals = { data, total_page };
    },

    getRewardsSuccess(state, action) {
      state.isLoading = false;
      const { transactions: data, total_page } = action.payload;
      state.rewards = { data, total_page };
    },

    getTransfersSuccess(state, action) {
      state.isLoading = false;
      const { transactions: data, total_page } = action.payload;
      state.transfers = { data, total_page };
    },

    setFailedTx(state, action) {
      state.isLoading = false;
      state.failedTx = action.payload;
    }
  }
});

export const { setPage, setFailedTx } = slice.actions;

export function getRewards(filters = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get("/reward/transactions", {
        params: filters,
        baseURL: BASE_URL
      });

      dispatch(slice.actions.getRewardsSuccess(data.data));
    } catch (error) {}
  };
}

export function getTransfers(filters = {}) {
  filters.types = '3,4'
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get("/reward/transactions", {
        params: filters,
        baseURL: BASE_URL
      });

      dispatch(slice.actions.getTransfersSuccess(data.data));
    } catch (error) {}
  };
}

export function getReferrals(filters = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get("/referral/history", {
        params: filters,
        baseURL: BASE_URL
      });

      dispatch(slice.actions.getReferralsSuccess(data.data));
    } catch (error) {}
  };
}

export function getFailedTx() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/reward/transactions", {
        params: { status: 0, type: 1 }, //Pending transactions for the "withdraw" type
        baseURL: BASE_URL
      });
      dispatch(slice.actions.setFailedTx(data.data.transactions[0] || null));
    } catch (error) {}
  };
}

export default slice.reducer;
