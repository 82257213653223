import { Box, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialog from "./Dialog";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import EthPng from "../../assets/icons/wallet/eth.png";
import { ReactComponent as AlertIcon } from "../../assets/icons/tooltip_alert.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomButton from "../Buttons/Button";
import useBreakpoints from "../../hooks/useBreakpoints";
import { currencies, getBalance } from "../../constants/currencies";
import { WALLET_CONFIG, wrongWalletAlert } from "../../redux/slices/wallet";
import { checkNetwork, handleChangeNetwork, signWithdraw, withdraw, setSelectedCurrency } from "../../redux/slices/DMA";
import useIsWrongNetwork from "../../hooks/useIsWrongNetwork";
import { NOTIF_FAIL, setNotification } from "../../redux/slices/notifications";
import { getCorrectChain } from "../../utils/chains";
import { ethers } from "ethers";

function InfoRow({ title, Icon = null, info, rowSx }) {
  return (
    <Box
      sx={{
        display: "flex",
        mt: 4,
        flexFlow: "row nowrap",
        ...rowSx
      }}>
      <Typography sx={{ fontSize: 14, minWidth: 115, color: (theme) => theme.palette.grey[60] }}>
        {title}
      </Typography>

      <Typography
        sx={{ fontSize: 14, display: "flex", alignItems: "flex-end", wordBreak: "break-word" }}>
        {Icon && <Icon />} {info}
      </Typography>
    </Box>
  );
}
const InputTextSx = {
  color: (theme) => theme.palette.grey[0],
  fontSize: { xxs: 18, xs: 24 },
  fontWeight: 600,
  py: 1.25
};

const RowContainerSx = {
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: { xxs: "flex-start", xs: "center" }
};

const InputSx = {
  height: { xxs: "40px", xs: "51px" },
  input: { ...InputTextSx },
  "& input[type=number]": {
    MozAppearance: "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none"
    }
  }
};

function CustomSelect({ value, setValue }) {
  const { withdraw } = useSelector((state) => state.DMA);

  return (
    <Box width={"100%"}>
      <FormControl fullWidth variant="standard">
        <Select
          variant="outlined"
          data-augmented-ui="tl-clip br-clip border"
          sx={{
            fontWeight: 600,
            position: "relative",
            width: "100%",
            fontSize: "20px",
            height: { xxs: "40px", xs: "51px" },
            "&:hover": {
              border: "none"
            },
            "&.Mui-focused ": {
              color: (theme) => theme.palette.text.primary,
              "& .MuiOutlinedInput-notchedOutline": {
                color: (theme) => theme.palette.text.primary,
                border: "transparent",
                borderWidth: 0
              }
            }
          }}
          inputProps={{
            sx: { display: "flex", alignItems: "center" }
          }}
          value={value}
          IconComponent={() => (
            <ArrowIcon style={{ position: "absolute", width: 10, height: "0.45rem", right: 20 }} />
          )}
          MenuProps={{ PaperProps: { sx: { mt: 1 } } }}
          onChange={(e) => setValue(e.target.value)}
          style={getAugmentedStyle({ color: "grey", borderSize: "1.5px" })}>
          <MenuItem
            disabled={!withdraw.usdt}
            disableRipple
            sx={{ fontSize: "20px", fontWeight: 600 }}
            value={currencies.USDT}>
            {currencies.USDT}
          </MenuItem>
          <MenuItem
            disabled={!withdraw.des}
            disableRipple
            sx={{ fontSize: "20px", fontWeight: 600 }}
            value={currencies.DES}>
            {currencies.DES}
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

function CustomInputField({ value, setValue, helperText = "", maxValue, minValue = 0 }) {
  function handleChangeQuantity(value) {
    if (value.length === 0) setValue("");
    else {
      const floatValue = parseFloat(value);

      if (value < minValue && value.length !== 0) setValue((prevState) => prevState);
      else setValue((prevState) => (floatValue <= maxValue ? floatValue : prevState));
    }
  }

  return (
    <Box display="flex" flexDirection="column" flexWrap="nowrap" width="100%">
      <TextField
        fullWidth
        type="number"
        variant="outlined"
        value={value}
        onChange={(e) => handleChangeQuantity(e.target.value)}
        data-augmented-ui="tl-clip br-clip border"
        InputProps={{
          inputProps: { max: maxValue, step: "0.01" }
        }}
        sx={InputSx}
        style={getAugmentedStyle({ color: "grey", borderSize: "1.5px" })}
      />
      <Box mt={0.5} display="flex" flexWrap="nowrap" justifyContent="space-between">
        <Typography color="textSecondary" variant="caption">
          {helperText}
        </Typography>
        <Typography
          color="primary"
          variant="caption"
          onClick={() => handleChangeQuantity(maxValue)}
          sx={{ textDecoration: "underline", mr: 0.5, cursor: "pointer" }}>
          Max
        </Typography>
      </Box>
    </Box>
  );
}

function EthIcon() {
  return (
    <Box mr={1} width={20} height={20} display="flex" justifyContent="center">
      <Box
        sx={{
          width: 12.56,
          height: 19.17,
          backgroundSize: "100%",
          backgroundImage: `url(${EthPng})`
        }}></Box>
    </Box>
  );
}

function WithdrawalDialog({ open, setClose }) {
  const dispatch = useDispatch();
  // const correctChain = getCorrectChain();
  const isWrongNetwork = useIsWrongNetwork();
  const { user } = useSelector((state) => state.auth);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { balance, withdrawProcessing, selectedCurrency } = useSelector((state) => state.DMA);
  const { useAccount, useProvider } = WALLET_CONFIG[selectedWallet].hook;
  // const connector = WALLET_CONFIG[selectedWallet].connector;
  const provider = useProvider();
  const account = useAccount();
  const [amount, setAmount] = useState(0);
  const downXs = useBreakpoints("down", "xs");
  const correctWallet = user?.wallets?.ethWallet;
  const isWrongWallet = correctWallet !== account;
  const selectedBalance = getBalance(balance, selectedCurrency);

  useEffect(() => {
    if (open && isWrongWallet) {
      dispatch(wrongWalletAlert(correctWallet));
    }
  }, [open, isWrongWallet, dispatch, correctWallet]);

  async function handleWithdraw() {
    if (isWrongWallet) {
      dispatch(wrongWalletAlert(correctWallet));
      return;
    }

    if (isWrongNetwork) {
      await handleChangeNetwork();
      return;
    }

    dispatch(signWithdraw(amount.toString(), selectedCurrency)).then((signData) => {
      dispatch(withdraw({ provider, signData }));
    });
  }

  return (
    <CustomDialog
      open={open}
      hasCloseButton={false}
      setClose={setClose}
      PaperProps={{
        sx: {
          width: { xxs: "101%", sm: 710 },
          height: { xxs: "55%", xs: "initial" },
          padding: { xxs: 0, xs: "0 6px" },
          margin: { xxs: 0, xs: 2 },
          left: -2,
          bottom: -2,
          background: { xxs: "#181818", xs: "black" },

          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            display: "none"
          }
        },
        style: {
          position: downXs ? "absolute" : "relative",
          "--aug-tr1": "12px",
          "--aug-tr-inset2": "calc(var(--aug-tr1) * 14.5)"
        },
        "data-augmented-ui": downXs ? "tr-clip-x border" : "bl-clip-y br-clip border"
      }}>
      <Box sx={{ pt: { xs: 3 }, pl: { xs: 7 }, pr: { xs: 11.5 }, pb: { xs: 5 } }}>
        <Typography fontSize={24} fontWeight={700}>
          Withdraw Rewards
        </Typography>
        <InfoRow title="Network" info="Ethereum Mainnet" Icon={() => <EthIcon />} />
        <InfoRow title="Wallet Address" info={user?.wallets?.ethWallet} />
        <Box mt={4} sx={RowContainerSx}>
          <InfoRow title="Asset" rowSx={{ mt: 0 }} />
          <CustomSelect
            value={selectedCurrency}
            setValue={(value) => {
              dispatch(setSelectedCurrency(value));
              setAmount(0);
            }}
          />
        </Box>
        <Box mt={4} sx={RowContainerSx}>
          <InfoRow title="Amount" rowSx={{ mt: 0 }} />
          <CustomInputField
            maxValue={selectedBalance?.amount}
            helperText={`${selectedBalance?.amount || 0} ${
              selectedBalance?.currency || ""
            } available`}
            value={amount}
            setValue={setAmount}
          />
        </Box>

        <Box
          sx={{ display: "flex", flexFlow: "column nowrap", alignItems: "center", width: "100%" }}
          mt={4}>
          <Box display="flex" flexWrap="nowrap" justifyContent="flex-start">
            <OutlinedButton
              size="medium"
              sx={{ fontSize: "16px", width: "116px", height: "37px", mr: 3 }}
              handleClick={setClose}
              label="Cancel"
              color="primary"
              disabled={withdrawProcessing}
              needCircular={false}
            />
            <CustomButton
              isLoading={withdrawProcessing}
              disabled={amount <= 0 || amount > balance}
              handleClick={handleWithdraw}
              sx={{ fontSize: "16px", width: "116px", height: "37px", mt: 0 }}
              augmented="tl-clip br-clip">
              Withdraw
            </CustomButton>
          </Box>

          <Typography sx={{ display: "flex", mt: 1 }} variant="caption" color="textSecondary">
            <AlertIcon style={{ marginRight: "6px" }} />
            Gas fees need to be provided for withdrawal
          </Typography>
        </Box>
      </Box>
    </CustomDialog>
  );
}

export default WithdrawalDialog;
