import { Box, Button, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { varZoomInOut } from "./Animate";
import copyTextToClipboard from "copy-to-clipboard";
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";
import { ReactComponent as CopiedIcon } from "../assets/icons/copied.svg";

function CopyText({ label, textToCopy, buttonVariant = "text", buttonSx = {} }) {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return (
    <Box display="flex" alignItems="center">
      <Button
        disableRipple
        variant={buttonVariant}
        sx={{
          p: 0,
          mr: 1,
          ml: 0.5,
          minWidth: "max-content",
          "& .MuiButton-endIcon": { m: 0 },
          "&:hover": {
            background: "none"
          },
          ...buttonSx
        }}
        onClick={() => {
          setCopied(true);
          console.log(textToCopy);
          copyTextToClipboard(textToCopy);
        }}
        endIcon={
          copied ? (
            <CopiedIcon sx={{ m: 0, cursor: "pointer" }} />
          ) : (
            <CopyIcon sx={{ m: 0, cursor: "pointer" }} />
          )
        }>
        {label}
      </Button>
      <AnimatePresence>
        {copied && (
          <Typography
            variant="caption"
            component={motion.span}
            sx={{
              color: (theme) => theme.palette.grey[70]
            }}
            {...varZoomInOut}>
            copied
          </Typography>
        )}
      </AnimatePresence>
    </Box>
  );
}

export default CopyText;
