// import { Box, styled, Tooltip, Typography } from "@mui/material";
// import useBreakpoints from "../../../hooks/useBreakpoints";
// import ToolTipBorderLg from "../../../assets/tooltip_border_large.svg";
// import ToolTipBorderSm from "../../../assets/tooltip_border_small.svg";
// import { ReactComponent as QuestionMarkIcon } from "../../../assets/icons/question_mark.svg";

// const ToBeStyledTooltip = ({ className, ...props }) => (
//   <Tooltip enterTouchDelay={0} {...props} classes={{ tooltip: className }} />
// );
// const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
//   color: theme.palette.grey[900],
//   backgroundColor: "transparent"
// }));

// export default function RefferalToolTip() {
//   const downLg = useBreakpoints("down", "lg");
//   const downXs = useBreakpoints("down", "xs");
//   return (
//     <StyledTooltip
//       sx={{ zIndex: 10000, background: "transparent" }}
//       //Use to control the distance between the anchor and the tooltip
//       PopperProps={{
//         modifiers: [
//           {
//             name: "offset",
//             options: {
//               offset: [downXs ? 27 : downLg ? 21 : 0, -16]
//             }
//           }
//         ]
//       }}
//       placement={downLg ? "bottom-end" : "right"}
//       componentsProps={{
//         tooltip: {
//           sx: {
//             background: {
//               xxs: `no-repeat center/100% url(${ToolTipBorderSm})`,
//               lg: `no-repeat center/100% url(${ToolTipBorderLg})`
//             }
//           }
//         }
//       }}
//       title={
//         <Box>
//           <Typography color="primary" px={0.75} py={1.25} fontSize={12} fontWeight={400}>
//             Claim function will come later. <br />
//             Please stay tuned to Delysium.
//           </Typography>
//         </Box>
//       }>
//       <Box
//         ml={0.5}
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         width={{ xxs: 20, xs: 32 }}
//         height={{ xxs: 20, xs: 32 }}>
//         <QuestionMarkIcon style={{ width: 20 }} />
//       </Box>
//     </StyledTooltip>
//   );
// }
import { Box, styled, Tooltip, Typography } from "@mui/material";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { ReactComponent as ToolTipIcon } from "../../../assets/icons/question_tooltip.svg";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip enterTouchDelay={0} {...props} classes={{ tooltip: className }} />
);
const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  color: theme.palette.grey[900]
}));

export default function RefferalToolTip() {
  return (
    <StyledTooltip
      sx={{ zIndex: 10000, background: "transparent", ml: 1 }}
      //Use to control the distance between the anchor and the tooltip
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              //
              offset: [4, -30]
            }
          }
        ]
      }}
      placement={"right-start"}
      title={
        <Box
          style={getAugmentedStyle({ tl: "12px", bl1: "0px" })}
          sx={{ background: (theme) => theme.palette.grey[300] }}
          data-augmented-ui="tl-scoop tr-clip bl-clip">
          <Typography maxWidth={253} px={1.3125} py={1.375} fontSize={12} fontWeight={400}>
            {/* {DMA_COPY_WRITING.tooltip.quantity} */}
            You will receive the referral reward within 20 minutes once your referee makes a DMA
            purchase. Please remember to check your wallet. If you haven't connected your wallet
            yet, please set it up in the account setting first to receive your reward.
          </Typography>
        </Box>
      }>
      <ToolTipIcon style={{ marginLeft: "4px", marginBottom: "4px" }} />
    </StyledTooltip>
  );
}
