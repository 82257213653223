import { PATH_DASHBOARD, PATH_HISTORY } from "./paths";
import { DMAView, SettingsView } from "../views/Dashboard";
import MainLayout from "../layouts/MainLayout";
import ActivityHistoryLayout from "../layouts/ActivityHistoryLayout";
import { Referral, Rewards, Transfer } from "../views/Dashboard/History";
import { Redirect } from "react-router-dom";

const DashboardRoutes = {
  layout: MainLayout,
  routes: [
    {
      layout: ActivityHistoryLayout,
      path: PATH_HISTORY.root,
      routes: [
        {
          path: PATH_HISTORY.referral,
          exact: true,
          component: () => <Referral />
        },
        {
          path: PATH_HISTORY.rewards,
          exact: true,
          component: () => <Rewards />
        },
        {
          path: PATH_HISTORY.transfer,
          exact: true,
          component: () => <Transfer />
        },
        {
          component: () => <Redirect to={PATH_HISTORY.rewards} />
        }
      ]
    },
    {
      path: PATH_DASHBOARD.dmaView,
      exact: true,
      component: () => <DMAView />
    },
    {
      path: PATH_DASHBOARD.settingsView,
      exact: true,
      component: () => <SettingsView />
    },
    {
      component: () => <Redirect to={PATH_DASHBOARD.dmaView} />
    }
  ]
};

export default DashboardRoutes;
