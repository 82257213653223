import { Box, Input, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { keyframes } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { getAugmentedStyle } from "../utils/augmentedStyles";

const blockInvalidChar = (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
const blink = keyframes`from,
49.8%{
  opacity: 1;
}
49.9%,
99.9% {
  opacity: 0;
}
to {
  opacity: 1;
}`;

function SplitByDigitInput({ length = 6, code, setCode }) {
  const theme = useTheme();

  const handleChange = (e) => {
    if (code.length < length || e.nativeEvent.inputType === "deleteContentBackward")
      setCode(e.target.value);
  };
  useEffect(() => {
    if (code.length > length) setCode((prev) => prev.slice(0, length));
  }, [code, length, setCode]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          justifyContent: "center"
        }}>
        {[...Array(length)].map((value, index) => (
          <Box
            key={index}
            style={getAugmentedStyle({
              color: theme.palette.grey[index === code.length ? 80 : 30],
              borderSize: "2px"
            })}
            data-augmented-ui="br-clip border"
            sx={{
              display: "flex",
              width: { xxs: "32px", md: "45.5px" },
              height: { xxs: "42px", md: "65px" },
              justifyContent: "center",
              alignItems: "center",
              mr: 1.5
            }}>
            {index === code.length ? (
              <Typography
                sx={{
                  fontSize: 34.67,
                  content: '""',
                  position: "absolute",
                  width: "1px",
                  height: { xxs: 20, md: 35 },
                  background: (theme) => theme.palette.primary.main,
                  left: "50%",
                  top: "50%",
                  transform: "translate3d(-50%, -50%, 0)",
                  animation: `${blink} 1s linear infinite`
                }}>
                {code[index] || ""}
              </Typography>
            ) : (
              <Typography sx={{ fontSize: { xs: 22, md: 34.67 } }}>{code[index] || ""}</Typography>
            )}
          </Box>
        ))}
      </Box>
      <Input
        onKeyDownCapture={blockInvalidChar}
        value={code}
        disableUnderline={true}
        onChange={handleChange}
        type="number"
        autoComplete="off"
        autoFocus
        onFocus={(event) => {
          event.target.select();
        }}
        onWheel={(event) => event.target.blur()}
        sx={{
          "& .MuiInput-input": {
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              WebkitAppearance: "none"
            }
          },
          width: { xxs: "260px", md: "400px" },
          height: { xxs: "42px", md: "65px" },
          caretColor: "transparent",

          color: "transparent",
          backgroundColor: "transparent",
          textShadow: "transparent",
          p: 0
        }}
      />
    </Box>
  );
}

export default SplitByDigitInput;
