import { Typography, CircularProgress, Grid, Divider } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInDown } from "../../../components/Animate";
import incentiveRulePNG from "../../../assets/incentive_rules.png";
import incentiveRuleMobilePNG from "../../../assets/incentive_rules_mobile.png";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert.svg";
import { ReactComponent as DESIcon } from "../../../assets/icons/des.svg";
import { ReactComponent as USDTIcon } from "../../../assets/icons/usdt.svg";
import { PATH_EXTERNAL, PATH_HISTORY } from "../../../routes/paths";
import { isWalletBrowser } from "../../../utils/isMobile";
import InfoCard, { CustomInfoCard, InfoCardSx } from "./InfoCard";
import InvitationCardDialog from "./InvitationCardDialog";
import {
  getDMAInfo,
  getWithdrawStatus,
  setSelectedCurrency,
  setTxReminderDialogOpen,
  setTransferDialogOpen,
  setWithdrawalDialogOpen,
} from "../../../redux/slices/DMA";
import WithdrawalDialog from "../../../components/Dialogs/WithdrawalDialog";
import TransferDialog, { TransferSuccessDialog } from "../../../components/Dialogs/TransferDialog";
import { PageHeading } from "../../../components/PageHeading";
import Container from "../../../components/Container";
import { isWalletBound } from "../../../redux/slices/auth";
import {
  setBindWalletNotice,
  setWalletDialogOpen,
  WALLET_CONFIG
} from "../../../redux/slices/wallet";
import TransactionReminderDialog from "../../../components/Dialogs/TransactionReminderDialog";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Button";
import OutlinedButton from "../../../components/Buttons/OutlinedButton";
import { toFixed, withComma } from "../../../utils/strings";
import { currencies } from "../../../constants/currencies";
import { getFailedTx } from "../../../redux/slices/history";
import useBreakpoints from "../../../hooks/useBreakpoints";

const balanceContainerSx = {
  width: "100%",
  display: "flex",
  flexFlow: "row wrap",
  alignItems: "center",
  justifyContent: { xxs: "flex-end", xs: "center" }
};

const balanceSx = {
  lineHeight: "45px",
  flexGrow: 1,
  textAlign: { xxs: "right", xs: "center" },
  width: { xs: "100%" },
  minWidth: "170px",
  mt: { xs: "44px" },
  mb: { xs: "12px" },
  fontSize: { xxs: "40px", sm: "60px" },
  fontFamily: "Teko"
};

const displayNum = (num = 0) => {
  if (num == 0) return 0;
  return withComma(toFixed(num));
};

function SectionTitle({ label }) {
  return (
    <Typography
      fontSize={{ xxs: 14, sm: 16, md: 18, lg: 20 }}
      fontWeight={500}
      mt={{ xxs: 2.5, xs: 7.5 }}
      mb={{ xxs: 1.875, xs: 3 }}
      textTransform="uppercase">
      {label}
    </Typography>
  );
}

function DMAView() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [openInvitationCard, setOpenInvitationCard] = useState(false);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useIsActive } = WALLET_CONFIG[selectedWallet].hook;
  const isActive = useIsActive();
  const {
    isLoading,
    stats,
    referral,
    balance,
    withdraw,
    txReminderDialogOpen,
    transferDialogOpen,
    withdrawalDialogOpen
  } = useSelector((state) => state.DMA);
  const { code, totalReferred, rewards } = referral;
  const { quantity, pendingQuantity } = stats;
  const upXS = useBreakpoints("up", "xs");
  const { failedTx } = useSelector((state) => state.history);

  const DESBalance = Number(balance.find((item) => item.currency === currencies.DES)?.amount || 0);
  const USDTBalance = Number(balance.find((item) => item.currency === currencies.USDT)?.amount || 0);

  const DESRewards = rewards.find((item) => item.currency === currencies.DES)?.amount || 0;
  const USDTRewards = rewards.find((item) => item.currency === currencies.USDT)?.amount || 0;

  const withdrawUSDTDisabled =
    USDTBalance === 0 || !withdraw.usdt

  const withdrawDESDisabled = DESBalance === 0 || !withdraw.des

  const transferDisabled =
    (DESBalance === 0)

  useEffect(() => {
    dispatch(getDMAInfo());
    dispatch(getFailedTx());
    dispatch(getWithdrawStatus());
  }, [dispatch]);

  function handleClickWithdraw(initialCurrency = currencies.USDT) {
    dispatch(setSelectedCurrency(initialCurrency));
    dispatch(getFailedTx());
    if (!isWalletBound(user)) {
      dispatch(setBindWalletNotice(true));
      return;
    }
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true }));
      return;
    }
    if (failedTx) {
      dispatch(setTxReminderDialogOpen(true));
      return;
    }

    dispatch(setWithdrawalDialogOpen(true));
  }

  function handleClickTransfer() {
    dispatch(setTransferDialogOpen(true));
  }

  return (
    <Container>
      <PageHeading
        title="Delysium Multiverse Accelerator"
        subtitle="Buy DMA to earn enormous reward!"
      />
      {isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <AnimatePresence>
          <Box component={motion.div} {...varFadeInDown}>
            <SectionTitle label="your balance" />
            <Box
              data-augmented-ui="tl-clip br-clip border"
              style={getAugmentedStyle({
                color: 'rgb(26 26 26)'
              })}
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "center",
                ...InfoCardSx,
                px: { xxs: "24px", xs: "30px" },
                py: { xxs: "24px", xs: "60px" }
              }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexFlow: { xxs: "column nowrap", xs: "row nowrap" },
                  alignItems: "flex-start",
                  justifyContent: "center"
                }}>
                <Box sx={balanceContainerSx} >
                  <Box
                    sx={{
                      display: "flex",
                      flexFlow: { xxs: "row nowrap", xs: "column nowrap", alignItems: "center" },
                    }}>
                    <USDTIcon style={{ height: "auto", width: upXS ? "36px" : "20px" }} />
                    <Typography
                      sx={{ mt: { xxs: "0", md: 1.25 }, ml: { xxs: 1, xs: 0.5 }, fontWeight: 500 }}
                      color="textPrimary">
                      {currencies.USDT}
                    </Typography>
                  </Box>

                  <Typography sx={balanceSx} variant="h" color="primary">
                    {displayNum(USDTBalance)}
                  </Typography>
                  <CustomButton
                    handleClick={() => handleClickWithdraw(currencies.USDT)}
                    augmented="tl-clip br-clip"
                    disabled={withdrawUSDTDisabled}
                    sx={{
                      mt: { xxs: 3, xs: 4 },
                      height: { xxs: "28px", xs: "32px" },
                      width: { xxs: "100px", xs: "100px" },
                      fontSize: "12px"
                    }}>
                    Withdraw
                  </CustomButton>
                </Box>
                <Divider
                  sx={{
                    mx: { xs: 2 },
                    my: { xxs: 3, xs: 0 },
                    width: { xxs: "100%", xs: "1px" },
                    height: { xxs: "1px", xs: "242px" }
                  }}
                  orientation={upXS ? "vertical" : "horizontal"}
                />
                <Box sx={balanceContainerSx}>

                  <Box
                    sx={{
                      display: "flex",
                      flexFlow: { xxs: "row nowrap", xs: "column nowrap", alignItems: "center" }
                    }}>
                    <DESIcon style={{ height: "auto", width: upXS ? "36px" : "20px" }} />
                    <Typography
                      sx={{ mt: { xxs: "0", md: 1.25 }, ml: { xxs: 1, xs: 0.5 }, fontWeight: 500 }}
                      color="textPrimary">
                      DES Token
                    </Typography>
                  </Box>
                  <Typography sx={balanceSx} variant="h" color="primary">
                    {displayNum(DESBalance)}
                  </Typography>
                  <Box display="flex" alignItems="flex-end">
                    <OutlinedButton
                      label="Transfer"
                      handleClick={handleClickTransfer}
                      augmented="tl-clip br-clip border"
                      disabled={transferDisabled}
                      needCircular={false}
                      sx={{
                        height: { xxs: "28px", xs: "32px" },
                        width: { xxs: "100px", xs: "100px" },
                        fontSize: "12px",
                      }}
                    />
                    <CustomButton
                      handleClick={() => handleClickWithdraw(currencies.DES)}
                      augmented="tl-clip br-clip"
                      disabled={withdrawDESDisabled}
                      sx={{
                        ml: 2,
                        mt: { xxs: 3, xs: 4 },
                        height: { xxs: "28px", xs: "32px" },
                        width: { xxs: "100px", xs: "100px" },
                        fontSize: "12px"
                      }}>
                      Withdraw
                    </CustomButton>
                  </Box>
                </Box>
              </Box>
            </Box>

            <SectionTitle label="your dma details" />
            <InfoCard
              hasToolTip
              title="Quantity"
              bodyDynamic={quantity}
              bodyDynamicAlert={pendingQuantity > 0 ? `( ${pendingQuantity} DMA Pending )` : null}
              secondaryButtonText="Learn More"
              handleSecondaryClick={() =>
                isWalletBrowser()
                  ? window.location.assign(PATH_EXTERNAL.gitbook.dma)
                  : window.open(PATH_EXTERNAL.gitbook.dma, "_blank")
              }
            />

            <Grid
              sx={{
                display: "grid",
                columnGap: 0.5,
                gridTemplateColumns: { xxs: "1fr", md: "1fr 1fr" }
              }}>
              <InfoCard
                title="Yesterday Incentives"
                bodyDynamic={displayNum(stats.yesterdayRewards?.amount)}
                bodyDynamicUnit={stats.yesterdayRewards?.currency || currencies.DES}
              />
              <InfoCard
                title="Total Incentives"
                bodyDynamic={displayNum(stats.rewards?.amount)}
                bodyDynamicUnit={stats.rewards?.currency || currencies.DES}
              />
            </Grid>

            <Box
              data-augmented-ui="tl-clip br-clip"
              style={getAugmentedStyle({})}
              sx={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "space-between",
                py: 3.75,
                px: 3,
                backgroundColor: `rgba(255, 255, 255, 0.07)`
              }}>
              <Typography fontSize={{ xxs: 14, sm: 16, lg: 18 }} fontWeight={500}>
                DMA Incentive Rules
              </Typography>
              <Box
                width="100%"
                height="auto"
                alt="dma incentive rules graph"
                component="img"
                my={3.75}
                src={upXS ? incentiveRulePNG : incentiveRuleMobilePNG}
              />
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row nowrap",
                  alignItems: "center",
                  mt: 2,
                  px: { xs: 1 }
                }}>
                <AlertIcon style={{ width: !upXS && "38px", height: "auto" }} />
                <Typography
                  variant="caption"
                  sx={{ fontSize: { xxs: "10px", xs: "12px" }, ml: 1.125 }}
                  color="textPrimary">
                  The amount of DES you will receive is proportional to the percentage of DMAs you
                  hold.{" "}
                  <Typography
                    variant="caption"
                    color="primary"
                    component="a"
                    sx={{
                      fontSize: { xxs: "10px", xs: "12px" },
                      textDecoration: "underline",
                      cursor: "pointer"
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.DELYSIUM_CLIENT_URL}/dma`}>
                    Buy More DMAs now.
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <InvitationCardDialog
              open={openInvitationCard}
              setClose={() => setOpenInvitationCard(false)}
            />
            <SectionTitle label="referral system" />
            <CustomInfoCard
              handleClick={() =>
                isWalletBrowser()
                  ? window.location.assign(PATH_EXTERNAL.gitbook.referral)
                  : window.open(PATH_EXTERNAL.gitbook.referral, "_blank")
              }
            />

            <InfoCard
              title="Your Referral Code"
              bodyDynamicSx={
                code && {
                  fontSize: { xxs: 36 },
                  color: (theme) => theme.palette.grey[0]
                }
              }
              handlePrimaryClick={() =>
                code
                  ? setOpenInvitationCard(true)
                  : isWalletBrowser()
                  ? window.location.assign(PATH_EXTERNAL.marketplace.dma)
                  : window.open(PATH_EXTERNAL.marketplace.dma, "_blank")
              }
              bodyStatic={code ? null : "Purchase DMA to obtain your referral code."}
              bodyDynamic={code ? code : null}
              copyText={code ? true : false}
              primaryButtonText={code ? "Invite" : "Buy DMA"}
            />
            <InfoCard
              primaryButtonText="View History"
              handlePrimaryClick={() => push(PATH_HISTORY.referral)}
              title="Your Referral"
              bodyDynamic={totalReferred}
              bodyDynamicUnit="DMA"
            />

            <Grid
              sx={{
                display: "grid",
                columnGap: 0.5,
                gridTemplateColumns: { xxs: "1fr", md: "1fr 1fr" }
              }}>
              <InfoCard
                title="Your USDT Rewards"
                bodyDynamic={displayNum(USDTRewards)}
                bodyDynamicUnit="USDT"
              />
              <InfoCard
                title="Your DES Rewards"
                bodyDynamic={displayNum(DESRewards)}
                bodyDynamicUnit="DES"
              />
            </Grid>
          </Box>
        </AnimatePresence>
      )}
      <WithdrawalDialog
        open={withdrawalDialogOpen}
        setClose={() => dispatch(setWithdrawalDialogOpen(false))}
      />
      <TransferDialog
        open={transferDialogOpen}
        setClose={() => dispatch(setTransferDialogOpen(false))}
      />
      <TransferSuccessDialog />
      <TransactionReminderDialog
        open={txReminderDialogOpen}
        setClose={() => dispatch(setTxReminderDialogOpen(false))}
      />
    </Container>
  );
}
export default DMAView;
