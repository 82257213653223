import jwtDecode from "jwt-decode";
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { PATH_EXTERNAL } from "../../routes/paths";
import { config } from "../../config";
import { resetAccountDialogs } from "./account";
import { resetWalletDialog } from "./wallet";
import { isWalletBrowser } from "../../utils/isMobile";
// ----------------------------------------------------------------------
const BASE_URL = config.SSO_API_URL;
const defaultHeader = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const initialState = {
  isLoading: false,
  isUpdating: false,
  error: false,
  user: null,
  isAuthenticated: false
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setIsUpdating(state, action) {
      state.isUpdating = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.isUpdating = false;
    },

    invalidateUser(state) {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;
export const { setIsUpdating, setUser } = slice.actions;

// ----------------------------------------------------------------------

export const isWalletBound = (user = { wallets: { ethWallet: "" } }) => {
  return user.wallets?.ethWallet?.length > 0 || false;
};

export const validateToken = (token) => {
  return async (dispatch) => {
    try {
      const userData = await axios.get(`auth/me`, {
        baseURL: BASE_URL,
        headers: { ...defaultHeader, Authorization: `Bearer ${token}` }
      });

      return userData.data;
    } catch (error) {
      return false;
    }
  };
};

export const initializeUser = (token) => {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading(true));
    dispatch(validateToken(token)).then((userData) => {
      if (userData) {
        dispatch(slice.actions.setUser({ ...userData, token: token }));
      } else {
        dispatch(slice.actions.invalidateUser());
        isWalletBrowser()
          ? window.location.assign(PATH_EXTERNAL.sso.login)
          : window.open(PATH_EXTERNAL.sso.login, "_self");
      }
    });
  };
};

export const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const getDiscordData = () => {
  return async (dispatch) => {
    try {
      return await axios.get(`discord/me`, { baseURL: BASE_URL });
    } catch (error) {
      return false;
    }
  };
};

export const updateUser = ({ user }) => {
  return async (dispatch) => {
    dispatch(slice.actions.setIsUpdating(true));
    try {
      dispatch(validateToken(user?.token)).then((response) => {
        if (response) {
          dispatch(getDiscordData(user?.token)).then((discordData) => {
            const userData = { ...response, token: user?.token, discord: discordData };
            dispatch(setUser({ ...user, ...userData }));
            dispatch(resetAccountDialogs());
            dispatch(resetWalletDialog());
          });
        } else {
          dispatch(slice.actions.invalidateUser());
          isWalletBrowser()
            ? window.location.assign(PATH_EXTERNAL.sso.login)
            : window.open(PATH_EXTERNAL.sso.login, "_self");
        }
      });
    } catch (error) {
      dispatch(slice.actions.setIsUpdating(false));
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await axios.post(`auth/logout`, {}, { baseURL: BASE_URL });
      window.location.reload();
    } catch (error) {
      window.location.reload();
    }
  };
};
