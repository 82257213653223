import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import routes, { renderRoutes } from "./routes";
import { store } from "./redux/store";
import { Provider, useSelector } from "react-redux";
import ThemeConfig from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import "./assets/augmented.css";
import { useCookies } from "react-cookie";
import { initializeUser } from "./redux/slices/auth";
import { metaMaskHooks, metaMask } from "./utils/connectors";
import { coinbaseHooks, coinbaseWallet } from "./utils/connectors";
import { walletConnectHooks, walletConnect } from "./utils/connectors";
import { Web3ReactProvider } from "@web3-react/core";
import "./App.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import { injectStore } from "./utils/axios";

const TOKEN_NAME = "dely-jwt";

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseHooks]
];

injectStore(store);

function RenderRoutes({ routes }) {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <>
      {isAuthenticated ? (
        renderRoutes(routes)
      ) : (
        <Box
          display="flex"
          height="100vh"
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <CircularProgress color="primary" size={30} />
          <Typography mt={2.75} fontSize={30} fontWeight={500} color="primary">
            Loading...
          </Typography>
        </Box>
      )}
    </>
  );
}

function App() {
  const [cookies] = useCookies([TOKEN_NAME]);

  useEffect(() => {
    const jwt = cookies[TOKEN_NAME];
    store.dispatch(initializeUser(jwt));
  }, []);

  return (
    <Provider store={store}>
      <ThemeConfig>
        <BrowserRouter>
          <Web3ReactProvider connectors={connectors}>
            <ScrollToTop />
            <RenderRoutes routes={routes} />
          </Web3ReactProvider>
        </BrowserRouter>
      </ThemeConfig>
    </Provider>
  );
}

export default App;
