import { ethers } from "ethers";
import { __DEBUG__, __STAGING__ } from "./dev";

const MATIC = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18
};

const ETH = {
  name: "Etherum",
  symbol: "ETH",
  decimals: 18
};

const AETH = {
  name: "AEtherum",
  symbol: "AETH",
  decimals: 18
};

const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

export const CHAINS = {
  1: {
    id: 1,
    rpc: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    name: "Etherum Mainnet",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://etherscan.io/"]
  },
  5: {
    id: 5,
    rpc: `https://eth-goerli.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    name: "Goerli Test Network",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://goerli.etherscan.io/"]
  },
  137: {
    id: 137,
    rpc: `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    name: "Polygon Mainnet",
    nativeCurrency: MATIC,
    blockExplorerUrls: ["https://polygonscan.com"]
  },
  80001: {
    id: 80001,
    rpc: `https://polygon-mumbai.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    name: "Polygon Mumbai",
    nativeCurrency: MATIC,
    blockExplorerUrls: ["https://mumbai.polygonscan.com"]
  },
  42161: {
    id: 42161,
    rpc: `https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    name: "Arbitrum Mainnet",
    nativeCurrency: AETH,
    blockExplorerUrls: ["https://arbiscan.io/"]
  },
  10: {
    id: 42161,
    rpc: `https://opt-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`,
    name: "Optimism Mainnet",
    nativeCurrency: ETH,
    blockExplorerUrls: ["https://optimistic.etherscan.io"]
  }
};

// export const DMA_CORRECT_CHAIN = __STAGING__ || __DEBUG__ ? CHAINS[5] : CHAINS[1];
export const CORRECT_CHAIN = __STAGING__ || __DEBUG__ ? CHAINS[5] : CHAINS[1];

export const getCorrectChain = (pathname) => {
  return CORRECT_CHAIN;
};

export const changeNetwork = async (chain) => {
  const { ethereum } = window;
  if (ethereum?.networkVersion !== chain.id) {
    // RPC expects 0xnon-zero hexdemical, but with custom networks when
    //converting chainID to hex it givces 0x0 so causes an error
    //So i created this hexChain and while loop to remove the 0 after the x at 0x0 so it will be a valid hex code
    let hexChain = ethers.utils.hexlify(chain.id);
    let validHex = false;
    while (!validHex) {
      if (hexChain.charAt(2) === "0") {
        hexChain = hexChain.slice(0, 2) + hexChain.slice(2 + 1);
      } else {
        validHex = true;
      }
    }

    try {
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexChain }]
      });
      console.log("ETH", ethereum);
      return true;
    } catch (err) {
      console.log(err);
      // This error code indicates that the chain has not been added to MetaMask.
      if (err.code === 4902) {
        return await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: chain.name,
              chainId: hexChain,
              nativeCurrency: chain.currency,
              rpcUrls: chain.rpc
            }
          ]
        });
      } else return false;
    }
  }
};
