import {
  CircularProgress,
  Divider,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyText from "../../../components/CopyText";
import { toFixed } from "../../../utils/strings";
import { Columns, EmptyRecords } from "./Table";
import { ReactComponent as TransferInIcon } from "../../../assets/icons/transfer-in.svg";
import { ReactComponent as TransferOutIcon } from "../../../assets/icons/transfer-out.svg";
import { formatDate } from "../../../utils/time";
import { Box } from "@mui/system";
import useBreakpoints from "../../../hooks/useBreakpoints";
import Pagination from "../../../components/Pagination";
import { getTransfers } from "../../../redux/slices/history";

const COLUMNS = ["Method", { title: "Email", sx: { width: "25%" } }, "Date", "Amount", "Balance"];
const EMPTY_RECORDS = "No Transfer Record";

const flexCellSx = {
  display: "flex",
  alignItems: "center",
  border: "none"
};

const copyTextSx = {
  width: "18px",
  height: "18px",
  "& svg": {
    transform: "scale(0.75)"
  },
  ml: 0.75
};

const TransferRecordCard = ({ record }) => {
  const RecordRow = ({ children, ...props }) => (
    <Box
      {...props}
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        ...props?.sx
      }}>
      {children}
    </Box>
  );

  return (
    <Box
      sx={{ backgroundColor: (theme) => theme.palette.grey[10], my: 0.5, width: "100%", p: 1.5 }}>
      <RecordRow>
        <Typography variant="caption">{formatDate(record.updated_at)}</Typography>
      </RecordRow>
      <Divider sx={{ my: 1.5 }} />

      <RecordRow sx={{ mt: 0 }}>
        <Typography sx={{ fontSize: "18px" }}>
          {record.type === 3 ? "Send" : "Receive"}
          <Box component="span" sx={{ ml: "4px", position: "relative", top: "2px" }}>
            {record.type === 3 ? (
              <TransferOutIcon width="14px" height="14px" />
            ) : (
              <TransferInIcon width="14px" height="14px" />
            )}
          </Box>
        </Typography>
        <Typography sx={{ fontSize: "18px" }}>
          {toFixed(record.amount)} {record.currency}
        </Typography>
      </RecordRow>

      <RecordRow>
        <Typography
          sx={{ display: "flex", flexFlow: "row nowrap", opacity: 0.5 }}
          color="textPrimary"
          variant="caption">
          {record?.email?.length > 0 && <>{record.email}</>}
        </Typography>
        <Typography sx={{ opacity: 0.5 }} color="textPrimary" variant="caption">
          Balance: {toFixed(record.balance)} {record.currency}
        </Typography>
      </RecordRow>
    </Box>
  );
};

function Transfer() {
  const PAGE_SIZE = 10;
  const dispatch = useDispatch();
  const upXs = useBreakpoints("up", "xs");
  const { transfers, isLoading } = useSelector((state) => state.history);
  const isEmpty = !isLoading && transfers.data?.length === 0;
  const [page, setPage] = useState(1);

  function handleChangePage(e, value) {
    setPage(value);
  }

  useEffect(() => {
    dispatch(getTransfers({ page_size: PAGE_SIZE, page }));
  }, [dispatch, page]);

  return (
    <>
      {upXs ? (
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="Referral Table">
            <Columns columnList={COLUMNS} />
            <TableBody>
              {!isLoading &&
                transfers?.data?.map((row) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Box component="span" sx={{ mr: 1.5, position: "relative", top: "2px" }}>
                          {row.type === 3 ? <TransferOutIcon /> : <TransferInIcon />}
                        </Box>
                        {row.type === 3 ? "Send" : "Receive"}
                      </TableCell>

                      <TableCell sx={flexCellSx}>
                        {row.email}
                        <CopyText buttonSx={copyTextSx} label="" textToCopy={row.email} />
                      </TableCell>

                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {formatDate(row.updated_at)}
                      </TableCell>

                      <TableCell>
                        {toFixed(row.amount)} {row.currency}
                      </TableCell>
                      <TableCell>
                        {toFixed(row.balance)} {row.currency}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          my={2.5}
          minHeight={!isLoading && !isEmpty && transfers?.data?.length < 4 && "65vh"}>
          {!isLoading &&
            transfers?.data?.map((row) => {
              return <TransferRecordCard record={row} />;
            })}
        </Box>
      )}
      {isLoading && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      )}
      {isEmpty && (
        <EmptyRecords
          sx={{ mt: 0, minHeight: { xxs: "60vh", xs: "initial" } }}
          title={EMPTY_RECORDS}
        />
      )}
      {!isEmpty && !isLoading && (
        <Pagination onChange={handleChangePage} count={transfers?.total_page} page={page} />
      )}
    </>
  );
}

export default Transfer;
