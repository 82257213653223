import { createSlice } from "@reduxjs/toolkit";
// import { AppState } from "./store";
import { metaMaskHooks, metaMask } from "../../utils/connectors";
import { coinbaseHooks, coinbaseWallet } from "../../utils/connectors";
// import { walletConnectHooks, walletConnect } from "../../utils/connectors";
import { METAMASK, COINBASE } from "../../utils/wallet";
import { ReactComponent as MetaMaskIcon } from "../../assets/icons/wallet/metamask.svg";
import { ReactComponent as CoinbaseIcon } from "../../assets/icons/wallet/coinbase.svg";
// import { ReactComponent as WalletConnectIcon } from "../../assets/icons/wallet/walletconnect.svg";
import { setAccountNotification } from "./accountNotifications";
import axios from "../../utils/axios";
import { config } from "../../config";
import { shortenString } from "../../utils/strings";

const base_url = config.SSO_API_URL;
const defaultHeader = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

export const WALLET_TYPES = {
  metamask: "metamask",
  coinbase: "coinbase"
};

export const WALLET_CONNECTION_TYPES = {
  bind: "bind",
  connect: "connect"
};

export const WALLET_CONFIG = {
  metamask: {
    name: METAMASK,
    hook: metaMaskHooks,
    connector: metaMask,
    Icon: MetaMaskIcon
  },
  coinbase: {
    name: COINBASE,
    hook: coinbaseHooks,
    connector: coinbaseWallet,
    Icon: CoinbaseIcon
  }
  // walletconnect: {
  //   name: WALLETCONNECT,
  //   hook: walletConnectHooks,
  //   connector: walletConnect,
  //   Icon: WalletConnectIcon
  // }
};

// Initial state
const initialState = {
  walletDialogOpen: false,
  bindWalletNoticeOpen: false,
  connectionType: WALLET_CONNECTION_TYPES.bind,
  selectedWallet: WALLET_TYPES.metamask,
  isLoading: false
};

// Actual Slice
export const slice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    // Action to set the authentication status
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setWalletDialogOpen(state, action) {
      state.walletDialogOpen = action.payload.open || false;
      state.connectionType = action.payload.type
        ? WALLET_CONNECTION_TYPES[action.payload.type]
        : WALLET_CONNECTION_TYPES.connect;
    },
    setBindWalletNotice(state, action) {
      state.bindWalletNoticeOpen = action.payload;
    },
    setSelectedWallet(state, action) {
      state.connectedWallet = WALLET_TYPES[action.payload];
    },
    resetWalletDialog(state) {
      state.walletDialogOpen = false;
    },
    setConnectionType(state, action) {
      state.connectionType = WALLET_CONNECTION_TYPES[action.payload];
    }
  }
});

export const {
  setIsLoading,
  setWalletDialogOpen,
  setWalletConfig,
  setSelectedWallet,
  resetWalletDialog,
  setBindWalletNotice,
  setConnectionType
} = slice.actions;

export default slice.reducer;

export function isBound({ walletAddress }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get(`/wallet/eth/is-bound`, {
        params: { walletAddress },
        baseURL: base_url
      });
      dispatch(slice.actions.setIsLoading(false));
      return data?.bound;
    } catch (error) {
      dispatch(setWalletDialogOpen(false));
      dispatch(slice.actions.setIsLoading(false));
      dispatch(setAccountNotification(error));
    }
  };
}
export function bindChallenge({ email, walletAddress }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.post(
        `/wallet/eth/challenge/bind-account`,
        { email, walletAddress },
        {
          baseURL: base_url
        }
      );
      dispatch(slice.actions.setIsLoading(false));
      return data;
    } catch (error) {
      dispatch(setWalletDialogOpen(false));

      dispatch(slice.actions.setIsLoading(false));
      dispatch(setAccountNotification(error));
    }
  };
}
export function bind({ token, walletAddress, signature }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      await axios.post(
        `/wallet/eth/bind-to-account`,
        { walletAddress, signature },
        {
          headers: { ...defaultHeader, Authorization: `Bearer ${token}` },
          baseURL: base_url
        }
      );
      dispatch(slice.actions.setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setWalletDialogOpen(false));

      dispatch(slice.actions.setIsLoading(false));
      dispatch(setAccountNotification(error));
    }
  };
}

export function wrongWalletAlert(wallet) {
  return async (dispatch) => {
    dispatch(
      setAccountNotification(
        {
          message: `Please switch to wallet: ${shortenString(wallet)} for withdrawal`
        },
        5000
      )
    );
  };
}
