import palette from "./palette";
import shadows from "./shadows";
import PropTypes from "prop-types";
import typography from "./typography";
import breakpoints from "./breakpoints";
import globalStyles from "./globalStyles";
import borderRadius from "./borderRadius";
import componentsOverride from "./overrides";
import useSettings from "../hooks/useSettings";
import React, { useMemo } from "react";
import { CssBaseline, GlobalStyles, StyledEngineProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

function ThemeConfig({ children }) {
  const { themeMode } = useSettings();
  const isLight = themeMode === "light";

  const themeOptions = useMemo(
    () => ({
      palette: palette[isLight ? "light" : "dark"],
      shadows: shadows[isLight ? "light" : "dark"],
      typography: typography,
      shape: borderRadius,
      breakpoints: breakpoints,

      components: componentsOverride({
        theme: {
          palette: palette[isLight ? "light" : "dark"],
          shadows: shadows[isLight ? "light" : "dark"],
          typography: typography,
          shape: borderRadius,
        },
      }),
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
        <GlobalStyles styles={(theme) => globalStyles(theme)} />
        <CssBaseline />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeConfig;
