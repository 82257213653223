import { createSlice } from "@reduxjs/toolkit";
import { setAccountNotification } from "./accountNotifications";
import axios from "../../utils/axios";
import { config } from "../../config";
import { DISCORD_REDIRECT_PARAMETER } from "../../routes/paths";
// ----------------------------------------------------------------------
const base_url = config.SSO_API_URL;

const initialState = {
  isLoading: false,
  currentFlow: null,
  formDialogOpen: false,
  UnbindWalletDialogOpen: false
};

const slice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCurrentFlow(state, action) {
      state.currentFlow = action.payload;
    },
    setFormDialogOpen(state, action) {
      state.formDialogOpen = action.payload;
    },
    setUnbindWalletDialogOpen(state, action) {
      state.UnbindWalletDialogOpen = action.payload;
    },
    resetAccountDialogs(state) {
      state.UnbindWalletDialogOpen = false;
      state.formDialogOpen = false;
      state.currentFlow = null;
    }
  }
});

// Reducer
export default slice.reducer;
export const {
  setIsLoading,
  setCurrentFlow,
  setFormDialogOpen,
  resetAccountDialogs,
  setUnbindWalletDialogOpen
} = slice.actions;

export function verifyCodeEmail({ email, verificationCode }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get(`/me/email/verify-email-code`, {
        params: { email, verificationCode },
        baseURL: base_url
      });
      dispatch(slice.actions.setIsLoading(false));
      return data?.isValid;
    } catch (error) {
      dispatch(slice.actions.setIsLoading(false));
      dispatch(setAccountNotification(error));
    }
  };
}
export function requestCodeEmail({ email }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      await axios.post(
        `/me/email/request-email-code`,
        { email },
        {
          baseURL: base_url
        }
      );
      dispatch(slice.actions.setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setAccountNotification(error));
      dispatch(setCurrentFlow(null));
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}
export function updateEmail({ email, verificationCode }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      await axios.put(
        `/me/email/update`,
        { email, verificationCode: parseInt(verificationCode) },
        {
          baseURL: base_url
        }
      );
      dispatch(slice.actions.setCurrentFlow(null));
      dispatch(slice.actions.setFormDialogOpen(false));
      dispatch(slice.actions.setIsLoading(false));

      return true;
    } catch (error) {
      dispatch(setAccountNotification(error));
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}
export function verifyCodePassword({ email, verificationCode }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      const { data } = await axios.get(`/me/password/verify-email-code`, {
        params: { email, verificationCode },
        baseURL: base_url
      });
      dispatch(slice.actions.setIsLoading(false));
      return data?.isValid;
    } catch (error) {
      dispatch(slice.actions.setIsLoading(false));
      dispatch(setAccountNotification(error));
    }
  };
}
export function requestCodePassword({ email }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      await axios.post(`/me/password/request-email-code`, { email }, { baseURL: base_url });
      dispatch(slice.actions.setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setCurrentFlow(null));
      dispatch(setAccountNotification(error));
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}
export function updatePassword({ newPassword, newPasswordConfirm }) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      await axios.put(
        `/me/password/update`,
        { newPassword, newPasswordConfirm },
        { baseURL: base_url }
      );
      dispatch(slice.actions.setCurrentFlow(null));
      dispatch(slice.actions.setFormDialogOpen(false));
      dispatch(slice.actions.setIsLoading(false));
      return true;
    } catch (error) {
      dispatch(setAccountNotification(error));
      dispatch(slice.actions.setIsLoading(false));
      dispatch(slice.actions.setCurrentFlow(null));
    }
  };
}

export function discordAuthoriseURL() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/oauth2/discord/authorize-url`, {
        params: { redirectUri: DISCORD_REDIRECT_PARAMETER },
        baseURL: base_url
      });
      return data?.url;
    } catch (error) {}
  };
}
export function discordLogin({ code, requestNonce, redirectUri }) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `/oauth2/discord/login`,
        { code, requestNonce, redirectUri },
        { baseURL: base_url }
      );
      return data;
    } catch (error) {}
  };
}
export function discordBind({ authKey, requestNonce, redirectUri }) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `/discord/bind`,
        { authKey, requestNonce, redirectUri },
        { baseURL: base_url }
      );
      return data;
    } catch (error) {
      window.location.href = "/settings";
    }
  };
}
export function discordUnbind() {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(`/discord/unbind`, { baseURL: base_url });
      dispatch(slice.actions.setUnbindWalletDialogOpen(false));
      return data;
    } catch (error) {
      dispatch(setAccountNotification(error));
    }
  };
}
