import { alpha, Button, useTheme, CircularProgress } from "@mui/material";
import { getAugmentedStyle } from "../../utils/augmentedStyles";

function OutlinedButton({
  label,
  handleClick,
  needCircular = true,
  disabled = false,
  classname = "augmented-white",
  color = "primary",
  ...props
}) {
  const theme = useTheme();

  return (
    <Button
      disabled={disabled}
      color={color}
      sx={{
        p: 0,
        fontSize: 12,
        fontWeight: 400,
        height: 28,
        cursor: disabled ? 'not-allowed' : 'pointer',
        width: { xxs: 110, xs: 114 },
        ...props?.sx
      }}
      style={getAugmentedStyle({
        color: alpha(theme.palette[color].main, disabled ? 0.5 : 1)
      })}
      variant="outlined"
      data-augmented-ui="tl-clip br-clip border"
      onClick={handleClick}>
      {disabled && needCircular ? <CircularProgress size={12} sx={{ color: theme.palette[color].main }} /> : label}
    </Button>
  );
}

export default OutlinedButton;
