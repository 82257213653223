import { alpha, Box, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { ReactComponent as LeftArrow } from "../../assets/icons/navigation_arrow_left.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/navigation_arrow_right.svg";
import TabGroup from "../../components/TabGroup";
import { menuOptions } from "../../constants/dashboard";
import { shortenString } from "../../utils/strings";

function MenuIcon({ isOpen, handleClick }) {
  return (
    <Box
      sx={{ cursor: "pointer", display: { xxs: "none", xs: "block", md: "none" } }}
      onClick={handleClick}>
      {isOpen ? <LeftArrow /> : <RightArrow />}
    </Box>
  );
}
function SideMenuOutline({ isOpen = false, setOpen = () => {} }) {
  const { user } = useSelector((state) => state.auth);
  const theme = useTheme();
  const topStyle = getAugmentedStyle({
    color: alpha(theme.palette.grey[700], 0.8),
    bottomBorderSize: "0px"
  });
  const bottomStyle = getAugmentedStyle({ color: theme.palette.grey[700] });
  const handleMenuIconClick = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "space-between",
        position: "sticky",
        width: { xs: isOpen ? 242 : 50, md: 242 },
        zIndex: (theme) => theme.zIndex.appBar - 1
      }}>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexFlow: "column nowrap",
          minHeight: `calc(95vh - (5rem))`,
          backgroundColor: (theme) => theme.palette.grey[900]
        }}>
        <Box
          style={topStyle}
          data-augmented-ui="tl-clip  border"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "53px",
            borderBottom: "1px solid",
            flexFlow: { sm: "row wrap", md: "row nowrap" },
            borderColor: "divider"
          }}>
          <Box
            sx={
              isOpen
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    ml: { xs: 2.8125, md: 0 }
                  }
                : {}
            }>
            {isOpen ? (
              <>
                <MenuIcon handleClick={handleMenuIconClick} />
                <Box sx={{ m: "0 auto" }}>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.grey[50],
                      fontWeight: 500,
                      textTransform: "lowercase"
                    }}>
                    {user?.email.length > 20 ? shortenString(user?.email) : user?.email}
                  </Typography>
                </Box>
              </>
            ) : (
              <MenuIcon handleClick={handleMenuIconClick} />
            )}
          </Box>
        </Box>

        <Box
          style={bottomStyle}
          data-augmented-ui=" bl-clip-y br-clip border"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent="space-between">
          <TabGroup isIconOnly={!isOpen} items={menuOptions} onChange={() => setOpen(false)} />
        </Box>
      </Box>
    </Box>
  );
}

export default SideMenuOutline;
