import { Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { menuOptions } from "../../constants/dashboard";

function MobileMenu() {
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(menuOptions.find(({ value }) => pathname.includes(value))?.value);
  }, [pathname]);
  return (
    <Tabs
      value={selected}
      classes={{ indicator: "indicator" }}
      textColor="inherit"
      TabIndicatorProps={{ children: <span /> }}
      sx={{
        "& .indicator": {
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",

          "& > span": {
            height: 3,
            width: "70.5%",
            backgroundColor: (theme) => theme.palette.primary.main
          }
        },

        width: "100vw",
        minHeight: 46,
        height: 46,
        pl: 2.5,
        borderBottom: (theme) => `0.5px solid ${theme.palette.grey[50]}`
      }}>
      {menuOptions.map(({ title, value }, index) => {
        const isSelected = pathname.includes(value);

        return (
          <Tab
            exact
            value={value}
            key={index}
            onClick={() => setSelected(value)}
            LinkComponent={NavLink}
            to={value}
            activeStyle={{ fontWeight: 600 }}
            disableRipple
            sx={{
              minHeight: 46,
              p: 0,
              textAlign: "left",
              minWidth: 34,
              mr: 2.5
            }}
            label={
              <Typography sx={{ fontSize: 16, fontWeight: isSelected ? 600 : 300 }}>
                {title}
              </Typography>
            }
          />
        );
      })}
    </Tabs>
  );
}

export default MobileMenu;
