import { Network, TokenWithdrawal } from "@delysium/client";
import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../config";
import { currencies } from "../../constants/currencies";
import withdrawNotifs from "../../constants/notifications";
import axios from "../../utils/axios";
import { CHAINS, changeNetwork, getCorrectChain } from "../../utils/chains";
import { __DEBUG__, __PROD__, __STAGING__ } from "../../utils/dev";
import { getFailedTx, setFailedTx } from "./history";
import { NOTIF_FAIL, NOTIF_PROGRESSING, NOTIF_SUCCESS, setNotification } from "./notifications";

const DMA_BASE_URL = config.DMA_API_URL;

const initialState = {
  isLoading: false,
  txReminderDialogOpen: false,
  transferDialogOpen: false,
  transferProcessing: false,
  transferSuccessDialogOpen: false,
  transferSuccessResult: null,
  withdrawalDialogOpen: false,
  selectedCurrency: currencies.USDT,
  withdrawProcessing: false,

  withdraw: {
    usdt: true,
    des: false
  },

  stats: {
    quantity: 0,
    pending: 3,
    rewards: { amount: 0, currency: currencies.DES },
    yesterdayRewards: { amount: 0, currency: currencies.DES }
  },

  balance: [],

  referral: {
    code: "",
    totalReferred: 0,
    rewards: []
  }
};

export const slice = createSlice({
  name: "DMA",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setWithdrawProcessing(state, action) {
      state.withdrawProcessing = action.payload;
    },

    setTransferProcessing(state, action) {
      state.transferProcessing = action.payload;
    },

    setWithdrawalDialogOpen(state, action) {
      state.withdrawalDialogOpen = action.payload;
    },

    setTransferDialogOpen(state, action) {
      state.transferDialogOpen = action.payload;
    },

    setTransferSuccessDialogOpen(state, action) {
      state.transferSuccessDialogOpen = action.payload;
    },

    setTxReminderDialogOpen(state, action) {
      state.txReminderDialogOpen = action.payload;
    },

    setBalance(state, action) {
      state.balance = action.payload;
    },

    setSelectedCurrency(state, action) {
      state.selectedCurrency = action.payload;
    },

    setReferralStats(state, action) {
      const { total_dma_referred, referral_code, rewards } = action.payload;
      state.referral = { rewards, code: referral_code, totalReferred: total_dma_referred };
    },

    setWithdrawStatus(state, action) {
      state.withdraw = action.payload;
    },
    withdrawSuccess(state, action) {
      state.txReminderDialogOpen = false;
      state.withdrawalDialogOpen = false;
      state.withdrawProcessing = false;
    },
    transferSuccess(state, action) {
      state.transferDialogOpen = false;
      state.transferProcessing = false;
      state.transferSuccessDialogOpen = true;
      state.transferSuccessResult = action.payload;
    },
    setDMAStats(state, action) {
      const { quantity, pending_quantity, rewards, yesterday_rewards } = action.payload;
      //For now only DES value is shown so index 0 is used
      state.stats = {
        quantity,
        pendingQuantity: pending_quantity,
        yesterdayRewards: yesterday_rewards[0],
        rewards: rewards[0]
      };
    }
  }
});

export const {
  setTxReminderDialogOpen,
  setTransferDialogOpen,
  setTransferSuccessDialogOpen,
  setWithdrawalDialogOpen,
  setSelectedCurrency,
  setIsLoading,
  setTransferProcessing,
  setWithdrawProcessing
} = slice.actions;

export function getDMAInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoading(true));
    try {
      await dispatch(getReferralStats());
      await dispatch(getRewardsBalance());
      await dispatch(getDMAStats());
      dispatch(slice.actions.setIsLoading(false));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getWithdrawStatus() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("", { baseURL: config.DMA_ARK_CONFIG });
      dispatch(slice.actions.setWithdrawStatus(data.dashboard?.withdraw || initialState.withdraw));
    } catch (e) {
      console.log(e);
    }
  };
}
export function getReferralStats() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/referral/statistics`, {
        baseURL: DMA_BASE_URL
      });

      dispatch(slice.actions.setReferralStats(data?.data.referral_details));
      return true;
    } catch (error) {}
  };
}

export function getRewardsBalance() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/reward/balance`, {
        baseURL: DMA_BASE_URL
      });

      dispatch(slice.actions.setBalance(data.data.balance));
      return true;
    } catch (error) {}
  };
}

export function checkEmailValidation(email) {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`/reward/transactions/prerequisites`, {
        email
      } , {
        baseURL: DMA_BASE_URL,
      });
      return data.data?.exist;
    } catch (error) {}
  };
}

export function getDMAStats() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/dma/statistics`, {
        baseURL: DMA_BASE_URL
      });
      dispatch(slice.actions.setDMAStats(data.data.dma_details));
      return true;
    } catch (error) {}
  };
}

export function signWithdraw(amount, currency, ptr) {
  return async (dispatch) => {
    try {
      const { sdk } = withdrawNotifs;
      const { data } = await axios.post(
        "/reward/transactions",
        { amount, currency },
        { baseURL: DMA_BASE_URL }
      );
      dispatch(
        setNotification({
          title: "Transaction Processing",
          type: NOTIF_PROGRESSING,
          description: sdk.processing
        })
      );
      return data.data;
    } catch (error) {
      const { api, sdk } = withdrawNotifs;
      dispatch(getFailedTx());
      dispatch(setWithdrawProcessing(false));
      dispatch(
        setNotification({
          title: "Purchase Failed",
          type: NOTIF_FAIL,
          description: api[error?.code] || sdk["failed"]
        })
      );
    }
  };
}

export async function handleChangeNetwork() {
  const correctChain = getCorrectChain();
  return await changeNetwork(CHAINS[correctChain.id]);
}

export function transfer({ email, amount }) {
  const { sdk } = withdrawNotifs;

  return async (dispatch) => {
    try {
      dispatch(setTransferProcessing(true));
      const { data } = await axios.post(
        "/reward/transactions",
        { amount, currency: "DES", type: 1, to_email: email },
        { baseURL: DMA_BASE_URL }
      );
      dispatch(getRewardsBalance());
      dispatch(slice.actions.transferSuccess(data.data));
    } catch (error) {
      // dispatch(slice.actions.setTransferDialogOpen(false));
      console.log(`Transfer failed`, { cause: error })
      dispatch(
        setNotification({
          title: "Transaction Failed",
          type: NOTIF_FAIL,
          description: error.message
        })
      );
      dispatch(setTransferProcessing(false));
    }
  }
}

export function withdraw({ provider, signData }) {
  return async (dispatch) => {
    const { sdk } = withdrawNotifs;

    const contract = new TokenWithdrawal(
      __DEBUG__
        ? "0x5964E193b4cFb79A3bed974Ff693dB7712a4d359"
        : __STAGING__
        ? "0x0B36bd36Cff19FE11eDba7dF645F08795f3A6679"
        : undefined
    );

    contract
      .initialize({
        network: __PROD__ ? Network.mainnet : Network.goerli,
        provider
      })
      .then(async () => {
        dispatch(setWithdrawProcessing(true));
        try {
          const { sign_data: signInfo, signature } = signData;
          const transaction = await contract.sdk.withdrawBySignature(
            signInfo.erc20_address,
            signInfo.to_address,
            signInfo.amount,
            signInfo.account_uuid,
            signInfo.request_id,
            signature
          );

          if (transaction) {
            dispatch(setFailedTx(null));
            dispatch(slice.actions.withdrawSuccess());
            dispatch(getDMAInfo());
            dispatch(
              setNotification({
                title: "Transaction Submitted",
                type: NOTIF_SUCCESS,
                description: sdk.successful()
              })
            );
          }
        } catch (e) {
          console.log(e);
          dispatch(getFailedTx());
          dispatch(slice.actions.setWithdrawalDialogOpen(false));
          dispatch(
            setNotification({
              title: "Transaction Failed",
              type: NOTIF_FAIL,
              description: sdk[e] || sdk["failed"]
            })
          );
          dispatch(setWithdrawProcessing(false));
        }
      });
  };
}

export default slice.reducer;
