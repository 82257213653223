import React from "react";
import CustomButton from "../Buttons/Button";
import EmailField from "../Fields/Input/Email";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import FormHeader from "./FormHeader";
function EmailSubmitForm({ handleSubmit }) {
  const { isLoading } = useSelector((state) => state.account);
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    onSubmit: (values, { setSubmitting }) => handleSubmit(values, setSubmitting),
    validationSchema: yup.object({
      email: yup.string().email("Invalid email").required("Required")
    })
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormHeader
        title="Type Your New Email"
        body="We will send a notification code to your new email address. Please verify before you click
        confirm!"
      />

      <EmailField
        handleBlur={formik.handleBlur}
        errors={formik.errors.email}
        touched={formik.touched.email}
        value={formik.values.email}
        handleChange={formik.handleChange}
      />
      <CustomButton
        isLoading={isLoading}
        disabled={!(formik.isValid && formik.dirty)}
        augmented="br-clip"
        type="submit"
        sx={{ mt: 1.75 }}>
        SEND CODE
      </CustomButton>
    </form>
  );
}

export default EmailSubmitForm;
