import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WALLET_CONFIG } from "../redux/slices/wallet";
import { getCorrectChain } from "../utils/chains";

const useIsWrongNetwork = () => {
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { useProvider, useChainId } = WALLET_CONFIG[selectedWallet].hook;
  const provider = useProvider();
  const chainId = useChainId();

  useEffect(() => {
    provider?.getNetwork().then(() => {
      //Checks if we are on the DMA page, if so, the correct chains will be from mainnet, otherwise from polygon
      const correctChain = getCorrectChain();
      if (chainId !== correctChain.id) {
        setIsWrongNetwork(true);
      } else setIsWrongNetwork(false);
    });
  }, [provider, chainId]);

  return isWrongNetwork;
};

export default useIsWrongNetwork;
