import VerificationCodeForm from "../../components/Forms/VerificationCode";
import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmailSubmitForm from "../../components/Forms/EmailSubmit";
import FormDialog from "../../components/Dialogs/FormDialog";
import UnbindWalletDialog from "../../components/Dialogs/UnbindWalletDialog";
import WalletDialog from "../../components/Dialogs/WalletDialog";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import CopyText from "../../components/CopyText";
import ResetPasswordForm from "../../components/Forms/ResetPassword";
import {
  discordAuthoriseURL,
  requestCodeEmail,
  requestCodePassword,
  resetAccountDialogs,
  setCurrentFlow,
  setFormDialogOpen,
  setUnbindWalletDialogOpen,
  updateEmail,
  updatePassword,
  verifyCodeEmail,
  verifyCodePassword
} from "../../redux/slices/account";
import AccountNotificationBar from "../../components/AccountNotificationBar";
import { setAccountNotification } from "../../redux/slices/accountNotifications";
import {
  resetWalletDialog,
  setWalletDialogOpen,
  WALLET_CONNECTION_TYPES
} from "../../redux/slices/wallet";
import { updateUser } from "../../redux/slices/auth";
import FormHeader from "../../components/Forms/FormHeader";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeInDown } from "../../components/Animate";
import useBreakpoints from "../../hooks/useBreakpoints";
import { PageHeading } from "../../components/PageHeading";
import Container from "../../components/Container";

const overFlowEllipsisSx = {
  textOverflow: "ellipsis",
  maxWidth: { xxs: 200, xs: 254, sm: 364, md: 444, lg: 501 },
  overflow: "hidden",
  whiteSpace: "nowrap"
};

const sharedTextSx = {
  fontSize: { xxs: 14, xs: 16 }
};

function BoxTitle({ title }) {
  return (
    <Typography color="primary" fontSize={{ xxs: 14, xs: 18 }} fontWeight={400}>
      {title}
    </Typography>
  );
}

function FieldLabel({ label }) {
  return (
    <Typography
      sx={{
        mt: { xxs: 1.875, xs: 3 },
        fontSize: 12,
        color: (theme) => theme.palette.grey[50]
      }}>
      {label}
    </Typography>
  );
}

function Settings(props) {
  const { user, isUpdating } = useSelector((state) => state.auth);
  const { currentFlow, formDialogOpen, UnbindWalletDialogOpen } = useSelector(
    (state) => state.account
  );
  const { walletDialogOpen } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const [currentDialogStep, setCurrentDialogStep] = useState("");

  const [email, setEmail] = useState();
  const [isUpdateEmail, setIsUpdateEmail] = useState(false);
  const [isDiscordBinding, setIsDiscordBinding] = useState(false);
  const downXs = useBreakpoints("down", "xs");

  useEffect(() => {
    dispatch(resetAccountDialogs());
    dispatch(resetWalletDialog());
  }, []);

  useEffect(() => {
    if (!(formDialogOpen || UnbindWalletDialogOpen || walletDialogOpen)) {
      dispatch(setCurrentFlow(null));
      dispatch(updateUser({ user }));
    }
  }, [formDialogOpen, UnbindWalletDialogOpen, walletDialogOpen]);
  useEffect(() => {
    setEmail(user?.email);
  }, [user]);

  useEffect(() => {
    if (currentFlow)
      requestCode(user?.email, () => {
        setCurrentDialogStep("verificationCode");
        dispatch(setFormDialogOpen(true));
      });
  }, [currentFlow]);

  const handlePasswordSubmit = (values) => {
    dispatch(
      updatePassword({
        newPassword: values.password,
        newPasswordConfirm: values.confirmPassword
      })
    ).then((response) => {
      if (response) {
        dispatch(
          setAccountNotification({
            message: "Your password has been changed successfully. "
          })
        );
      }
    });
  };
  const handleEmailSubmit = (values, setSubmitting) => {
    requestCode(values.email, () => {
      setEmail(values.email);
      setIsUpdateEmail(true);
      setCurrentDialogStep("verificationCode");
      setSubmitting(false);
    });
  };

  const handleEmailCodeSubmit = (e, code) => {
    e.preventDefault();

    if (currentFlow === "emailUpdate")
      if (!isUpdateEmail) {
        dispatch(verifyCodeEmail({ email: user?.email, verificationCode: code })).then(
          (response) => {
            if (response) {
              setCurrentDialogStep("emailInput");
            }
          }
        );
      } else {
        dispatch(updateEmail({ email, verificationCode: code })).then((response) => {
          if (response) {
            dispatch(
              setAccountNotification({
                message: "Your email address has been changed successfully. "
              })
            );
          }
        });
      }
    else if (currentFlow === "passwordUpdate") {
      dispatch(verifyCodePassword({ email, verificationCode: code })).then((response) => {
        if (response) {
          setCurrentDialogStep("passwordInput");
        }
      });
    }
  };

  const requestCode = (email, onSuccess) => {
    if (currentFlow === "emailUpdate")
      dispatch(requestCodeEmail({ email })).then((response) => {
        if (response) onSuccess();
      });
    else if (currentFlow === "passwordUpdate")
      dispatch(requestCodePassword({ email })).then((response) => {
        if (response) onSuccess();
      });
  };

  const handleEmailResendCode = (email, setCoolDownTimer) => {
    requestCode(email, () => setCoolDownTimer(60));
  };

  const dialogFlow = {
    verificationCode: (
      <VerificationCodeForm
        handleResendCode={handleEmailResendCode}
        email={email}
        handleSubmit={handleEmailCodeSubmit}
      />
    ),
    emailInput: <EmailSubmitForm handleSubmit={handleEmailSubmit} />,
    passwordInput: (
      <>
        <FormHeader title="Enter Your New Password" />

        <ResetPasswordForm handleSubmit={handlePasswordSubmit} />
      </>
    )
  };

  return (
    <Container>
      <PageHeading
        title="ACCOUNT SETTINGS"
        subtitle={
          !(user?.wallets?.ethWallet && user?.discord)
            ? " Let’s complete setting up your account!"
            : null
        }
      />

      {isUpdating ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            mt: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      ) : (
        <AnimatePresence>
          <Box component={motion.div} {...varFadeInDown}>
            <Box mt={{ xxs: 3.75, xs: 7.5 }}>
              <BoxTitle title="Email Account" />
              <Box>
                <FieldLabel label="Email Address" />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    mt: { xxs: 0, xs: 1 },
                    pb: { xxs: 1.875, xs: 3 },
                    borderBottom: (theme) => `1px solid ${theme.palette.grey[30]}`
                  }}>
                  <Typography sx={{ ...overFlowEllipsisSx, ...sharedTextSx }}>
                    {user?.email}
                  </Typography>
                  <OutlinedButton
                    color="secondary"
                    sx={{ ml: 6 }}
                    disabled={currentFlow === "emailUpdate"}
                    label="Change Email"
                    handleClick={() => {
                      !currentFlow && setIsUpdateEmail(false);
                      !currentFlow && dispatch(setCurrentFlow("emailUpdate"));
                    }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  flexFlow: "row wrap",
                  mt: { xxs: 1.875, xs: 3 }
                }}>
                <Typography sx={sharedTextSx}>Password</Typography>
                <OutlinedButton
                  color="secondary"
                  disabled={currentFlow === "passwordUpdate"}
                  label="Reset Password"
                  handleClick={() => !currentFlow && dispatch(setCurrentFlow("passwordUpdate"))}
                />
              </Box>
            </Box>

            <Box mt={{ xxs: 3.75, xs: 7.5 }}>
              {user?.wallets?.ethWallet ? (
                <Box>
                  <BoxTitle title="Wallet" />
                  <Box>
                    <FieldLabel label="Wallet Address" />
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        mt: { xxs: 0, xs: 1 }
                      }}>
                      <Typography sx={sharedTextSx}>
                        {downXs
                          ? `${user?.wallets.ethWallet.substr(
                              0,
                              6
                            )}...${user?.wallets.ethWallet.substr(
                              user?.wallets.ethWallet.length - 6,
                              6
                            )}`
                          : user?.wallets.ethWallet}
                      </Typography>
                      <CopyText label="" textToCopy={user?.wallets.ethWallet} />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <BoxTitle title="Wallet" />
                  <OutlinedButton
                    disabled={walletDialogOpen}
                    color="primary"
                    classname="augmented-button-primary"
                    label="Bind"
                    handleClick={() => {
                      dispatch(
                        setWalletDialogOpen({ open: true, type: WALLET_CONNECTION_TYPES.bind })
                      );
                      // dispatch(setIsUCBinding(true));
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box mt={{ xxs: 3.75, xs: 7.5 }}>
              {user?.discord ? (
                <Box>
                  <BoxTitle title="Discord" />
                  <Box>
                    <FieldLabel label="Discord Email Address" />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt={{ xxs: 0, xs: 1 }}>
                      <Typography sx={{ ...overFlowEllipsisSx, ...sharedTextSx }}>
                        {user?.discord?.data.email}
                      </Typography>
                      <OutlinedButton
                        color="secondary"
                        label="Unbind"
                        handleClick={() => {
                          dispatch(setUnbindWalletDialogOpen(true));
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <BoxTitle title="Discord" />

                  <OutlinedButton
                    disabled={isDiscordBinding}
                    color="primary"
                    classname="augmented-button-primary"
                    label="Connect"
                    handleClick={() => {
                      setIsDiscordBinding(true);
                      dispatch(discordAuthoriseURL()).then((url) => (window.location.href = url));
                    }}
                  />
                </Box>
              )}
            </Box>
            <FormDialog
              open={formDialogOpen}
              closeDialog={() => {
                dispatch(setCurrentFlow(null));
                dispatch(setFormDialogOpen(false));
              }}>
              {dialogFlow[currentDialogStep]}
            </FormDialog>

            <UnbindWalletDialog
              open={UnbindWalletDialogOpen}
              setClose={() => {
                dispatch(setCurrentFlow(null));
                dispatch(setUnbindWalletDialogOpen(false));
              }}
            />
            <WalletDialog
              handleClose={() => {
                dispatch(setCurrentFlow(null));
                dispatch(setWalletDialogOpen(false));
              }}
            />
          </Box>
        </AnimatePresence>
      )}
    </Container>
  );
}

export default Settings;
