import { Box } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

function CloseButton({ onClick, Icon = null, ...props }) {
  return (
    <Box
      sx={{
        width: { xxs: 20, md: 24 },
        position: "absolute",
        right: "0.5rem",
        top: "0.5rem",
        opacity: "0.8",
        "&:hover": {
          opacity: 1,
          cursor: "pointer"
        },
        ...props?.sx
      }}
      onClick={onClick}>
      {Icon ? <Icon /> : <CloseIcon />}
    </Box>
  );
}

export default CloseButton;
