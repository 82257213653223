import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as EmptyCheckboxIcon } from "../../../assets/icons/checkbox_empty.svg";
import { ReactComponent as FilledCheckboxIcon } from "../../../assets/icons/checkbox_filled.svg";
import { ReactComponent as HoverCheckboxIcon } from "../../../assets/icons/checkbox_hover.svg";

function CheckBoxField({
  name = "",
  label,
  checked,
  handleChange,
  formSx = {},
  labelSx = {},
  CheckedIcon = null,
  DefaultIcon = null
}) {
  const [isHover, setIsHover] = useState(false);
  return (
    <FormControlLabel
      sx={{ zIndex: 1, m: 0, ...formSx }}
      control={
        <Checkbox
          disableRipple
          sx={{
            p: 0,
            "&:hover": {
              background: DefaultIcon && "none"
            },

            cursor: DefaultIcon && "default"
          }}
          name={name}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          icon={
            DefaultIcon ? <DefaultIcon /> : isHover ? <HoverCheckboxIcon /> : <EmptyCheckboxIcon />
          }
          checkedIcon={CheckedIcon ? <CheckedIcon /> : <FilledCheckboxIcon />}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={
        <Typography sx={{ ml: 0.5, fontSize: 14, fontWeight: 400, ...labelSx }}>{label}</Typography>
      }
    />
  );
}

export default CheckBoxField;
