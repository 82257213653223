import { ReactComponent as DMAIcon } from "../assets/icons/dma.svg";
import { ReactComponent as DMAHighLightedIcon } from "../assets/icons/dma_highlighted.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/setting.svg";
import { ReactComponent as SettingsHighLightedIcon } from "../assets/icons/setting_highlighted.svg";
import { ReactComponent as HistoryIcon } from "../assets/icons/history.svg";
import { ReactComponent as HistoryHighlightedIcon } from "../assets/icons/history_highlighted.svg";
import { PATH_DASHBOARD, PATH_HISTORY } from "../routes/paths";

export const menuOptions = [
  {
    title: "DMA",
    Icon: DMAIcon,
    HighLightedIcon: DMAHighLightedIcon,
    value: PATH_DASHBOARD.dmaView
  },
  {
    title: "Account Settings",
    Icon: SettingsIcon,
    HighLightedIcon: SettingsHighLightedIcon,
    value: PATH_DASHBOARD.settingsView
  },
  {
    title: "Activity History",
    Icon: HistoryIcon,
    HighLightedIcon: HistoryHighlightedIcon,
    value: PATH_HISTORY.root
  }
];
