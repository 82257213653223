import { createSlice } from "@reduxjs/toolkit";

export const NOTIF_SUCCESS = "success";
export const NOTIF_FAIL = "fail";
export const NOTIF_PROGRESSING = "progress";

const initialState = {
  isOpen: false,
  type: NOTIF_PROGRESSING,
  title: "Hey",
  description: "This is a notification",
};

export const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setOpen(state) {
      state.isOpen = true;
    },

    setClose(state) {
      state.isOpen = false;
    },

    setNotification(state, action) {
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
  },
  extraReducers: (builder) => {},
});

export function setNotification({ type, title, description, time = 10000 }) {
  return async (dispatch) => {
    dispatch(slice.actions.setNotification({ type, title, description }));
    dispatch(slice.actions.setOpen());
    setTimeout(() => {
      dispatch(slice.actions.setClose());
    }, time);
  };
}

export default slice.reducer;
export const { setClose } = slice.actions;
