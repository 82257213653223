import { useTheme } from "@mui/system";
import { ReactComponent as FirstPageIcon } from "../assets/icons/double_left.svg";
import { ReactComponent as arrowRightIcon } from "../assets/icons/single_right.svg";
import { ReactComponent as arrowLeftIcon } from "../assets/icons/single_left.svg";
import { ReactComponent as lastPageIcon } from "../assets/icons/double_right.svg";
import { Pagination, PaginationItem } from "@mui/material";
import { getAugmentedStyle } from "../utils/augmentedStyles";

const CustomPagination = ({ page, onChange = () => {}, count = 1, ItemProps = {}, ...props }) => {
  const theme = useTheme();
  return (
    <Pagination
      shape="rounded"
      variant="outlined"
      color="primary"
      page={page}
      onChange={onChange}
      boundaryCount={1}
      siblingCount={2}
      count={count}
      showFirstButton
      showLastButton
      {...props}
      sx={{
        display: "flex",
        flexGrow: "1",
        alignItems: "flex-end",
        justifyContent: { xxs: "center", xs: "flex-end" },
        width: "100%",
        alignSelf: "flex-end",
        mt: 1.5,
        pb: { xxs: 9, xs: 0 },
        ...props.sx
      }}
      renderItem={(item) => {
        return (
          <PaginationItem
            disableRipple
            slots={{
              first: FirstPageIcon,
              last: lastPageIcon,
              next: arrowRightIcon,
              previous: arrowLeftIcon
            }}
            data-augmented-ui="br-clip tl-clip border"
            style={getAugmentedStyle({
              borderSize: "1.1px",
              color: item.selected && theme.palette[item.color]?.main
            })}
            {...item}
            {...ItemProps}
          />
        );
      }}
    />
  );
};

export default CustomPagination;
