// ----------------------------------------------------------------------

import { alpha } from "@mui/material";

const borderOffSet = "-6px";
const sharedBeforeAfterStyle = {
  content: "''",
  position: "absolute",
  width: "11px",
  height: "11px",
  zIndex: 4,
  borderColor: "inherit",
  transform: "rotate(-45deg)"
};

export default function Menu({ theme }) {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          position: "relative",
          color: "inherit",
          background: theme.palette.background.default,
          border: "1px solid",
          borderColor: theme.palette.text.primary,

          "&::before": {
            top: borderOffSet,
            left: borderOffSet,
            borderBottom: "1px solid",
            backgroundColor: theme.palette.background.default,
            ...sharedBeforeAfterStyle
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",

          "&:hover": {
            backgroundColor: alpha(theme.palette.text.primary, 0.2)
          },

          "&.Mui-selected": {
            backgroundColor: alpha(theme.palette.text.primary, 0.1),
            "&:hover": {
              backgroundColor: alpha(theme.palette.text.primary, 0.2)
            }
          }
        }
      }
    }
  };
}
