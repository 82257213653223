import {
  CircularProgress,
  Divider,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyText from "../../../components/CopyText";
import { shortenString, toFixed } from "../../../utils/strings";
import { Columns, EmptyRecords } from "./Table";
import { Box } from "@mui/system";
import { formatDate } from "../../../utils/time";
import useBreakpoints from "../../../hooks/useBreakpoints";
import {
  setWalletDialogOpen,
  WALLET_CONFIG,
  WALLET_CONNECTION_TYPES,
  wrongWalletAlert
} from "../../../redux/slices/wallet";
import Pagination from "../../../components/Pagination";
import { getRewards } from "../../../redux/slices/history";
import { checkNetwork, handleChangeNetwork, withdraw } from "../../../redux/slices/DMA";
import useIsWrongNetwork from "../../../hooks/useIsWrongNetwork";
import { NOTIF_FAIL, setNotification } from "../../../redux/slices/notifications";
import { ethers } from "ethers";
import { getCorrectChain } from "../../../utils/chains";

const COLUMNS = ["Transaction", "Wallet Address", "Date", "TxID", "Amount", "Balance", "Status"];
const EMPTY_RECORDS = "No Rewards Record";

const ORDER_TYPES = {
  withdrawal: "Withdrawal",
  reward: "Rewards",
  daily: "Daily Rewards"
};

const copyTextSx = {
  width: "18px",
  height: "18px",
  "& svg": {
    transform: "scale(0.75)"
  },
  ml: 0.75
};

function getOrderType(type) {
  if (type === 0) return ORDER_TYPES.reward;
  if (type === 1) return ORDER_TYPES.withdrawal;
  else return ORDER_TYPES.daily;
}

const TryAgain = ({ transaction, sx, ...props }) => {
  const dispatch = useDispatch();
  const isWrongNetwork = useIsWrongNetwork();
  const { selectedWallet } = useSelector((state) => state.wallet);
  const { withdrawProcessing } = useSelector((state) => state.DMA);
  const { useIsActive, useAccount, useProvider } = WALLET_CONFIG[selectedWallet]?.hook;
  const provider = useProvider();
  const isActive = useIsActive();
  const account = useAccount();
  const correctWallet = transaction?.sign_data?.to_address;
  const isWrongWallet = correctWallet !== account;

  async function handleCompleteTransaction() {
    if (!isActive) {
      dispatch(setWalletDialogOpen({ open: true, type: WALLET_CONNECTION_TYPES.connect }));
      return;
    }

    if (isWrongWallet) {
      dispatch(wrongWalletAlert(correctWallet));
      return;
    }

    if (isWrongNetwork) {
      await handleChangeNetwork();
      return;
    }

    dispatch(withdraw({ provider, signData: transaction }));
  }

  return (
    <Typography
      size="small"
      variant="text"
      color="primary"
      onClick={!withdrawProcessing && handleCompleteTransaction}
      sx={{
        textDecoration: "underline",
        fontWeight: "bold",
        height: "initial",
        ml: 5,
        cursor: "pointer",
        ...sx
      }}
      {...props}>
      {withdrawProcessing ? (
        <CircularProgress style={{ width: "20px", height: "20px" }} />
      ) : (
        "Try Again"
      )}
    </Typography>
  );
};

function RewardRecordCard({ record }) {
  const RecordRow = ({ children, ...props }) => (
    <Box
      {...props}
      sx={{
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "space-between",
        ...props?.sx
      }}>
      {children}
    </Box>
  );

  return (
    <Box
      sx={{ backgroundColor: (theme) => theme.palette.grey[10], my: 0.5, width: "100%", p: 1.5 }}>
      <RecordRow>
        <Typography variant="caption">{formatDate(record.updated_at)}</Typography>

        {record.status === 0 ? (
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.palette.error.dark, fontWeight: 600 }}>
            Unfinished
          </Typography>
        ) : (
          <Typography variant="caption" sx={{ color: "#7FFAE2", fontWeight: 600 }}>
            {record.status === 1 ? "Completed" : "Pending"}
          </Typography>
        )}
      </RecordRow>

      <RecordRow sx={{ mt: 3 }}>
        <Typography sx={{ fontSize: "18px" }}>{getOrderType(record.type)}</Typography>
        <Typography sx={{ fontSize: "18px" }}>
          {toFixed(record.amount)} {record.currency}
        </Typography>
      </RecordRow>

      <RecordRow>
        <Typography
          sx={{ display: "flex", flexFlow: "row nowrap", opacity: 0.5 }}
          color="textPrimary"
          variant="caption">
          {record?.wallet_address?.length > 0 && (
            <>
              Address: {shortenString(record.wallet_address, 4, 4)}{" "}
              <CopyText buttonSx={copyTextSx} label="" textToCopy={record.wallet_address} />
            </>
          )}
        </Typography>
        <Typography sx={{ opacity: 0.5 }} color="textPrimary" variant="caption">
          Balance: {toFixed(record.balance)} {record.currency}
        </Typography>
      </RecordRow>

      {getOrderType(record.type) === ORDER_TYPES.withdrawal && <Divider sx={{ my: 2 }} />}

      <RecordRow>
        <Typography
          sx={{ display: "flex", flexFlow: "row nowrap", opacity: 0.5 }}
          color="textPrimary"
          variant="caption">
          {record.transaction_hash > 0 && (
            <>
              TxID: {shortenString(record.transaction_hash, 4, 4)}{" "}
              <CopyText buttonSx={copyTextSx} label="" textToCopy={record.transaction_hash} />
            </>
          )}
        </Typography>
        {record.status === 0 && <TryAgain transaction={record} />}
      </RecordRow>
    </Box>
  );
}

function StatusComponent({ data }) {
  if (data.status === 0) {
    return (
      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <Typography variant="body2" sx={{ color: (theme) => theme.palette.error.dark }}>
          Unfinished
        </Typography>
        <TryAgain transaction={data} />
      </Box>
    );
  } else
    return (
      <Typography variant="body2" sx={{ color: "#7FFAE2" }}>
        {data.status === 1 ? "Completed" : "Pending"}
      </Typography>
    );
}

const flexCellSx = {
  display: "flex",
  alignItems: "center",
  border: "none"
};

function Rewards() {
  const PAGE_SIZE = 10;
  const dispatch = useDispatch();
  const upXs = useBreakpoints("up", "xs");
  const { isLoading, rewards, failedTx } = useSelector((state) => state.history);
  const isEmpty = !isLoading && rewards?.data?.length === 0;
  const [page, setPage] = useState(1);

  function handleChangePage(e, value) {
    setPage(value);
  }

  useEffect(() => {
    dispatch(getRewards({ page_size: PAGE_SIZE, page }));
  }, [dispatch, page, failedTx]);

  return (
    <>
      {upXs ? (
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="Rewards Table">
            <Columns columnList={COLUMNS} />
            <TableBody>
              {!isLoading &&
                rewards?.data?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{getOrderType(row.type)}</TableCell>
                      <TableCell sx={flexCellSx}>
                        {row?.wallet_address?.length > 0 ? (
                          <>
                            {shortenString(row.wallet_address, 4, 4)}
                            <CopyText
                              buttonSx={copyTextSx}
                              label=""
                              textToCopy={row.wallet_address}
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>
                        {formatDate(row.updated_at)}
                      </TableCell>
                      <TableCell sx={flexCellSx}>
                        {row?.transaction_hash?.length > 0 ? (
                          <>
                            {shortenString(row.transaction_hash, 4, 4)}
                            <CopyText
                              buttonSx={copyTextSx}
                              label=""
                              textToCopy={row.transaction_hash}
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell>{`${toFixed(row.amount)} ${row.currency}`}</TableCell>
                      <TableCell>{`${toFixed(row.balance)} ${row.currency}`}</TableCell>
                      <TableCell>
                        <StatusComponent data={row} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          my={2.5}
          minHeight={!isLoading && !isEmpty && rewards?.data?.length < 4 && "65vh"}>
          {!isLoading &&
            rewards?.data?.map((row, index) => {
              return <RewardRecordCard key={index} record={row} />;
            })}
        </Box>
      )}

      {isLoading && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 7.5
          }}>
          <CircularProgress color="primary" size={30} />
        </Box>
      )}

      {isEmpty && (
        <EmptyRecords
          sx={{ mt: 0, minHeight: { xxs: "60vh", xs: "initial" } }}
          title={EMPTY_RECORDS}
        />
      )}

      {!isEmpty && !isLoading && (
        <Pagination onChange={handleChangePage} count={rewards?.total_page} page={page} />
      )}
    </>
  );
}

export default Rewards;
