import { alpha, Box } from "@mui/material";
import CustomDialog from "./Dialog";
import { FormNotificationBar } from "../AccountNotificationBar";
function FormDialog({ open, closeDialog, children, ...props }) {
  return (
    <CustomDialog
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: {
            xxs: 335,
            xs: 357.5,
            md: 520
          },
          height: {
            xxs: 450,
            md: 656
          }
        }
      }}
      setClose={closeDialog}
      open={open}
      sx={{
        "&::-webkit-scrollbar": {
          width: 0
        }
      }}
      {...props}>
      <Box mt={{ xxs: 3.25, xs: 0 }}>
        <Box
          sx={{
            position: { xxs: "relative", xs: "absolute" },

            top: { xxs: 0, xs: "1rem" },
            left: { xxs: 0, xs: "3rem" }
          }}>
          <FormNotificationBar />
        </Box>
        <Box sx={{ mx: { xxs: 0.75, xs: 4.15625, md: 6.5 } }}>{children}</Box>
      </Box>
    </CustomDialog>
  );
}
export default FormDialog;
