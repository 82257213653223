import { Typography } from "@mui/material";

export function PageHeading({ title, subtitle = null, sx = {} }) {
  return (
    <>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: { xxs: 16, xs: 22, sm: 24, md: 26, lg: 28 },
          textTransform: "uppercase",
          ...sx
        }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          sx={{
            mt: 1,
            color: (theme) => theme.palette.grey[50],
            fontSize: { xxs: 12, sm: 14, lg: 16 },
            fontWeight: 500
          }}>
          {subtitle}
        </Typography>
      )}
    </>
  );
}
