import React, { useState } from "react";
import { Tab, Tabs, Typography, Popper, useTheme } from "@mui/material";
import SelectedTabBorder from "../assets/navigation-selected.svg";
import HoverTabBorder from "../assets/navigation-hover.svg";
import { AnimatePresence, motion } from "framer-motion";
import { varFadeIn } from "./Animate";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { getAugmentedStyle } from "../utils/augmentedStyles";
import { NavLink, useLocation } from "react-router-dom";
import { PATH_DASHBOARD, PATH_HISTORY } from "../routes/paths";

// ----------------------------------------------------------------------
TabGroup.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};
// ----------------------------------------------------------------------
const sharedSX = {
  overflow: "initial",
  height: "initial",
  width: "80%",
  margin: "0 auto",
  opacity: "initial"
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

function SVGBorderContainer() {
  return (
    <AnimatePresence>
      <motion.img
        src={SelectedTabBorder}
        style={{
          position: "absolute",
          zIndex: -1,
          top: "50%",
          left: 0,
          transform: "translate(0, -50%)",
          maxWidth: "initial",
          maxHeight: "48px",
          height: "auto",
          width: "100%"
        }}
        {...varFadeIn}
      />
    </AnimatePresence>
  );
}

function TabGroup({
  isIconOnly = false,
  value,
  label,
  items,
  handleClick = () => {},
  onChange = () => {},
  ...props
}) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveringOver, setHoveringOver] = useState({ title: "", value: "" });

  const handleHoverOpen = (option, event) => {
    const { currentTarget } = event;
    setOpen(true);
    setAnchorEl(currentTarget);
    setHoveringOver(option);
  };

  const handleHoverClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const activeStyle = {
    color: theme.palette.primary.main,
    fontWeight: 600
  };

  return (
    <>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        TabIndicatorProps={{ sx: { display: "none" } }}
        textColor="inherit"
        value={0}
        onChange={onChange}
        aria-label={label}
        {...props}
        sx={{ py: 1, ...props?.sx }}>
        {items.map(({ title, Icon, HighLightedIcon, value }, index) => {
          const isSelected = pathname.includes(value);
          return (
            <Tab
              onMouseEnter={(e) => handleHoverOpen({ title, value }, e)}
              onMouseLeave={(e) => handleHoverClose(e)}
              onClick={() => handleClick(value)}
              LinkComponent={NavLink}
              to={value}
              activeStyle={activeStyle}
              key={index}
              value={index}
              disableRipple
              {...a11yProps(value)}
              sx={{
                ...sharedSX,
                fontWeight: 300,
                p: isIconOnly ? 0 : "1.5 2",
                minWidth: isIconOnly && 50,
                color: theme.palette.grey[70],

                "&:hover": {
                  backgroundImage: !isSelected && !isIconOnly && `url(${HoverTabBorder})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain"
                }
              }}
              label={
                <>
                  <Box
                    sx={
                      !isIconOnly
                        ? {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%"
                          }
                        : null
                    }>
                    {isSelected ? <HighLightedIcon /> : <Icon />}

                    {!isIconOnly && (
                      <Typography
                        sx={{
                          ml: 1.25,
                          mr: "auto",
                          fontSize: 14,
                          fontWeight: isSelected ? 600 : 300
                        }}>
                        {title}
                      </Typography>
                    )}
                  </Box>
                  {isSelected && !isIconOnly && <SVGBorderContainer />}
                </>
              }
            />
          );
        })}
      </Tabs>
      {isIconOnly && (
        <Popper
          style={{ position: "relative", zIndex: 1900 }}
          open={open}
          anchorEl={anchorEl}
          placement="right">
          <Box
            data-augmented-ui="br-clip"
            sx={{ ml: 1, py: 0.75, px: 3 }}
            style={getAugmentedStyle({
              backgroundColor: theme.palette.grey[600]
            })}>
            <Typography sx={{ fontSize: 12 }}>{hoveringOver.title}</Typography>
          </Box>
        </Popper>
      )}
    </>
  );
}

export default TabGroup;
