/* eslint-disable no-useless-escape */
export function shortenString(value = "", start = 5, end = 11) {
  return `${value.substr(0, start)}...${value.substr(value.length - end, end)}`;
}

export function toFixed(value = 0, decimal = 2) {
  return parseFloat(value).toFixed(decimal);
}

export function withComma(num = 0) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
