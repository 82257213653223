import { Toolbar, Box, AppBar, Container, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import bgEffect from "../../../assets/bg-effect.png";
import { PAGES } from "../../../constants/pages";
import { logout } from "../../../redux/slices/auth";
import LogoutButton from "../../../components/Buttons/LogoutButton";
import { isWalletBrowser } from "../../../utils/isMobile";

const CDN_ASSETS = {
  LOGO: "https://storage.delysium.com/ui/delysium_logo.png"
};

const trapezoidStyles = {
  content: "''",
  width: "11px",
  height: "100%",
  position: "absolute",
  top: 0,
  right: "-11px",
  backgroundImage: `url(${bgEffect})`,
  backgroundSize: "100px",
  clipPath: "polygon(-1px -1px,11px 11px,100% 100%,-1px 100%)"
};

const appBarSX = {
  position: "relative",
  width: "initial",
  mt: "20px",
  backgroundImage: `url(${bgEffect})`,
  backgroundSize: "100px",
  backgroundColor: "initial",
  color: (theme) => theme.palette.primary.main,

  "&::after": {
    ...trapezoidStyles
  }
};

const screenLine = {
  content: "''",
  position: "absolute",
  zIndex: (theme) => theme.zIndex.tooltip,
  background: (theme) => theme.palette.primary.main,
  transition: "all .2s ease-in-out",
  pointerEvents: "none"
};
const navLinkSx = {
  position: "relative",
  display: "block",
  height: "100%",
  margin: (theme) => `0 ${theme.spacing(2)}`,
  textDecoration: "none",
  textTransform: "uppercase",
  transition: "all 0.4s ease",

  "&:hover": {
    "& $navHorizLines": {
      "&::before": {
        top: 0,
        opacity: 0.3
      },
      "&::after": {
        bottom: 0,
        opacity: 0.3
      }
    },
    "& $navVertLines": {
      "&::before": {
        left: 0,
        opacity: 0.3
      },
      "&::after": {
        right: 0,
        opacity: 0.3
      }
    }
  }
};

const activeNavLinkSx = {
  "&:hover": {
    background: "none"
  },
  "&::before": {
    content: "''",
    position: "absolute",
    bottom: "-7px",
    left: (theme) => `${theme.spacing(3)}`,
    right: (theme) => `${theme.spacing(3)}`,
    borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`
  }
};

const navVertLines = {
  "&::before, &::after": {
    ...screenLine,
    top: 0,
    width: "1px",
    height: "200vh",
    marginTop: "-100vh"
  },

  "&::before": {
    left: "-30px",
    opacity: 0
  },

  "&::after": {
    right: "-30px",
    opacity: 0
  }
};

const navHorizLines = {
  "&::before, &::after": {
    ...screenLine,
    left: 0,
    width: "200vw",
    height: "1px",
    marginLeft: "-100vw"
  },

  "&::before": {
    top: "-30px",
    opacity: 0
  },

  "&::after": {
    bottom: "-30px",
    opacity: 0
  }
};

const Navbar = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: (theme) => theme.zIndex.modal - 1,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "space-between"
      }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
        <AppBar sx={appBarSX} position="static">
          <Container sx={{ ml: "26px", pr: 0 }} maxWidth="xl">
            <Toolbar style={{ height: "53px", minHeight: "53px" }} disableGutters>
              <Box
                component="img"
                onClick={() =>
                  isWalletBrowser()
                    ? window.location.assign("https://delysium.com/")
                    : window.open("https://delysium.com/", "_blank")
                }
                src={CDN_ASSETS.LOGO}
                sx={{
                  cursor: "pointer",
                  mr: "6px",
                  width: "108px",
                  display: "flex"
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  height: "39px",
                  alignItems: "center"
                }}>
                {PAGES.map(({ title, sublist = false }, index) => (
                  <Box key={index} sx={{ ...navLinkSx, ...activeNavLinkSx }}>
                    <Box sx={navVertLines} />
                    <Box sx={navHorizLines} />
                    <Button
                      disableRipple
                      sx={{
                        textTransform: "uppercase",
                        px: "15px",
                        height: "100%",
                        fontSize: 18,
                        fontWeight: 400,
                        "&:hover": {
                          background: "none"
                        }
                      }}>
                      {title}
                    </Button>
                  </Box>
                ))}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Box
          sx={{
            flexGrow: 0,
            zIndex: (theme) => theme.zIndex.modal + 1,
            mr: 5,
            mt: "18px",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-evenly"
          }}>
          <LogoutButton handleClick={() => dispatch(logout(user?.token))} />
        </Box>
      </Box>
    </Box>
  );
};
export default Navbar;
