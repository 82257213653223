import { Box, Dialog, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { TwitterShareButton } from "react-share";
import CustomButton from "../../../components/Buttons/Button";
import CloseButton from "../../../components/Buttons/CloseButton";
import OutlinedButton from "../../../components/Buttons/OutlinedButton";
import CustomDivider from "../../../components/Divider";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { PATH_EXTERNAL } from "../../../routes/paths";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close_basic.svg";
import CardBorder from "../../../assets/card-border.svg";
import CardBorderSmall from "../../../assets/card-border-small.svg";
import CardBorderMobile from "../../../assets/card-border-mobile.svg";

const invitationCardButtonSx = {
  mt: 0,
  width: { xxs: 120, xs: 194 },
  height: { xxs: 30, xs: 48 },
  fontSize: { xxs: 12, xs: 18 }
};

export default function InvitationCardDialog({ open, setClose }) {
  const downMd = useBreakpoints("down", "md");
  const downXs = useBreakpoints("down", "xs");
  const { referral } = useSelector((state) => state.DMA);
  const handleImageDownload = () => {
    html2canvas(document.querySelector("#capture"), {
      scale: 10,
      width: downXs ? 335 : downMd ? 500 : 608,
      height: downXs ? 186 : downMd ? 270 : 329,
      backgroundColor: null
    })
      .then((canvas) => {
        const link = document.createElement("a");
        link.download = "delysium-referral-code.png";
        link.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        link.click();
      })
      .catch((e) => {});
  };
  return (
    <Dialog
      open={open}
      onClose={setClose}
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => theme.palette.grey[900]
        }
      }}
      PaperProps={{ sx: { backgroundImage: "none" } }}>
      <InvitationCard setClose={setClose} />
      <Box display="flex" justifyContent="center" mt={7.5}>
        <TwitterShareButton
          url={PATH_EXTERNAL.delysium.dma}
          title={`DMA private sale is now available! Enter this exclusive code to buy DMA: ${referral?.code} @The_Delysium`}>
          <OutlinedButton color="primary" sx={invitationCardButtonSx} label="Share to Twitter" />
        </TwitterShareButton>
        <CustomButton
          augmented="tl-clip br-clip"
          sx={{ ml: 3.75, ...invitationCardButtonSx }}
          handleClick={handleImageDownload}>
          Download Card
        </CustomButton>
      </Box>
    </Dialog>
  );
}

function InvitationCard({ setClose }) {
  const { referral } = useSelector((state) => state.DMA);
  const downMd = useBreakpoints("down", "md");
  const downXs = useBreakpoints("down", "xs");
  return (
    <Box
      id="capture"
      sx={{
        position: "relative",
        width: { xxs: 335, xs: 501, md: 608 },
        minHeight: { xxs: 186, xs: 271, md: 329 },
        m: 1
      }}>
      <img
        style={{ position: "absolute" }}
        width={downXs ? 335 : downMd ? 501 : 608}
        height={downXs ? 186 : downMd ? 271 : 329}
        src={downXs ? CardBorderMobile : downMd ? CardBorderSmall : CardBorder}
        alt="invitation card"
      />
      <Box
        sx={{
          width: { xxs: 335, xs: 501, md: 608 },
          minHeight: { xxs: 186, xs: 271, md: 329 },
          position: "absolute",
          pl: { xxs: 3.125, xs: 4.67625, md: 5.6775 },
          pr: { xxs: 3.375, xs: 4.615, md: 5.46875 },
          pt: { xxs: 4.875, xs: 7.82, md: 9.5 }
        }}>
        <CloseButton
          sx={{
            top: { xxs: "0.5rem", xs: "1rem" },
            right: "0.75rem",
            width: { xxs: 10, xs: 20 },
            height: { xxs: 10, xs: 20 }
          }}
          onClick={setClose}
          data-html2canvas-ignore="true"
          Icon={CloseIcon}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            fontSize={{ xxs: 16, xs: 23.13, md: 28 }}
            fontWeight={600}
            textTransform="uppercase">
            DMA private sale
          </Typography>
          <Typography
            color="primary"
            fontSize={{ xxs: 12, md: 14 }}
            fontWeight={600}
            textTransform="uppercase">
            Referral only
          </Typography>
        </Box>
        <Typography
          fontSize={{ xxs: 12, xs: 14.87, md: 18 }}
          fontWeight={600}
          textTransform="capitalize"
          mt={2.1875}
          mb={{ xxs: 1, xs: 2.68375 }}>
          your referral code
        </Typography>
        <CustomDivider />
        <Typography
          fontFamily={"Teko, sans-serif"}
          fontSize={{ xxs: 40, xs: 66.1, md: 80 }}
          fontWeight={400}
          textTransform="uppercase"
          textAlign="right"
          lineHeight={1.25}>
          {referral?.code}
        </Typography>
      </Box>
    </Box>
  );
}
