import { Box, Typography, useTheme } from "@mui/material";
import CustomButton from "../../../components/Buttons/Button";
import OutlinedButton from "../../../components/Buttons/OutlinedButton";
import CopyText from "../../../components/CopyText";
import useBreakpoints from "../../../hooks/useBreakpoints";
import { getAugmentedStyle } from "../../../utils/augmentedStyles";
import RefferalToolTip from "./ToolTip";
import { ReactComponent as TrophyIcon } from "../../../assets/icons/trophy.svg";

const sharedButtonSx = {
  mt: 0,
  width: { xxs: 85, sm: 94, lg: 102 },
  height: { xxs: 28, lg: 32 },
  fontSize: 12,
  fontWeight: 400,
  padding: "6px 8px"
};

export const InfoCardSx = {
  width: "100%",
  py: { xxs: 2.3125, xs: 1.8125, sm: 1.875, md: 3.125, lg: 3.75 },
  backgroundColor: (theme) => theme.palette.grey[10],
  px: { xxs: 3.25, xs: 5 },
  mb: 0.625
};

export default function InfoCard({
  title,
  cardSx = {},
  BodyStatic = null,
  bodyStatic = null,
  copyText = false,
  bodyStaticSX = {},
  bodyDynamic = 0,
  bodyDynamicSx = {},
  bodyDynamicAlert = null,
  bodyDynamicUnit = null,
  secondaryButtonText = null,
  secondaryButtonDisabled = false,
  handleSecondaryClick = () => {},
  primaryButtonText = null,
  handlePrimaryClick = () => {},
  primaryButtonDisabled = false,
  hasToolTip = false,
  verticalAlignment = false,
  children
}) {
  const theme = useTheme();
  const augmentedStyle = getAugmentedStyle({
    color: theme.palette.grey[900]
  });

  return (
    <Box
      data-augmented-ui="tl-clip br-clip border"
      style={augmentedStyle}
      sx={{
        ...InfoCardSx,
        ...cardSx
      }}>
      <Box
        display="flex"
        mb={1.875}
        justifyContent={verticalAlignment && "center"}
        alignItems="center">
        <Typography mr={0.5} fontSize={{ xxs: 14, sm: 16, lg: 18 }} fontWeight={500}>
          {title}
        </Typography>
        {hasToolTip && <RefferalToolTip />}
      </Box>
      <Box
        display="flex"
        flexDirection={verticalAlignment && "column"}
        justifyContent="space-between"
        alignItems="center"
        mt={verticalAlignment && 0.625}>
        {BodyStatic ? (
          <BodyStatic />
        ) : bodyStatic ? (
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: (theme) => theme.palette.grey[60],
                maxWidth: { xs: 225, sm: 310, md: 380, lg: 423 },
                ...bodyStaticSX
              }}>
              {bodyStatic}
            </Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            mb={verticalAlignment && 5}
            flexDirection={verticalAlignment && "column"}
            alignItems={verticalAlignment ? "center" : "flex-start"}>
            <Box display="flex" alignItems="center">
              <Typography
                color="primary"
                sx={{
                  display: "inline-flex",
                  fontFamily: "Teko, sans-serif",
                  fontSize: { xxs: 40, md: 50, lg: 60 },
                  fontWeight: 400,
                  lineHeight: { xxs: 0.8, sm: 0.9 },
                  ...bodyDynamicSx
                }}>
                {bodyDynamic.toLocaleString("en")}
              </Typography>

              {copyText && <CopyText label="" textToCopy={bodyDynamic} />}
            </Box>
            {bodyDynamicUnit && (
              <Typography
                display="inline-flex"
                lineHeight={1.2}
                fontSize={{ xxs: 16, lg: 18 }}
                fontWeight={700}
                ml={0.625}
                color="primary">
                {bodyDynamicUnit}
              </Typography>
            )}
            {bodyDynamicAlert && (
              <Typography
                sx={{
                  ml: 0.6875,
                  mb: 0.6875,
                  alignSelf: "flex-end",
                  color: (theme) => theme.palette.grey[50]
                }}>
                {bodyDynamicAlert}
              </Typography>
            )}
          </Box>
        )}
        <Box display="flex" alignItems="center" pl={1}>
          {/* @TODO: add tietery button */}
          {secondaryButtonText && (
            <OutlinedButton
              disabled={secondaryButtonDisabled}
              sx={sharedButtonSx}
              color="primary"
              label={secondaryButtonText}
              handleClick={handleSecondaryClick}
            />
          )}
          {primaryButtonText && (
            <CustomButton
              disabled={primaryButtonDisabled}
              augmented="tl-clip br-clip"
              sx={{ ...sharedButtonSx, ml: secondaryButtonText && 2.5 }}
              handleClick={handlePrimaryClick}>
              {primaryButtonText}
            </CustomButton>
          )}
        </Box>
      </Box>
    </Box>
  );
}
const trophyIconSx = (downXs, downLg) => {
  return { width: downXs ? 97 : downLg ? 100 : 110, height: downXs ? 97 : downLg ? 100 : 110 };
};

export function CustomInfoCard({ handleClick }) {
  const theme = useTheme();
  const augmentedStyle = getAugmentedStyle({
    color: theme.palette.primary.main
  });

  const downLg = useBreakpoints("down", "lg");
  const downXs = useBreakpoints("down", "xs");
  return (
    <Box
      style={augmentedStyle}
      data-augmented-ui="tl-clip br-clip border"
      sx={{
        py: { xxs: 2.5, sm: 2.0625, md: 2.9375, lg: 3.6875 },
        backgroundColor: (theme) => theme.palette.grey[900],
        pl: { xxs: 2, xs: 3.75 },
        pr: { xxs: 2, xs: 2.75 },
        mb: { xxs: 1.875, xs: 3 }
      }}>
      <Typography color="primary" fontSize={{ xxs: 14, xs: 16, md: 18, lg: 20 }} fontWeight={600}>
        Get rewards through referral
      </Typography>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Box>
          <Typography
            fontSize={{ xxs: 12, lg: 14 }}
            fontWeight={400}
            mt={2.5}
            mb={{ xxs: 1.25, xs: 2.5 }}>
            Share your referral code to others to get referral rewards. The rewards can be directly
            claimed to your wallet.
          </Typography>
          <OutlinedButton
            handleClick={handleClick}
            label="Learn More"
            color="primary"
            sx={sharedButtonSx}
          />
        </Box>
        <Box sx={trophyIconSx(downXs, downLg)}>
          <TrophyIcon style={trophyIconSx(downXs, downLg)} />
        </Box>
      </Box>
    </Box>
  );
}
