import { useState } from "react";
import { Toolbar, AppBar, Container } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import bgEffect from "../../../assets/bg-effect.png";
import { AnimatePresence, motion } from "framer-motion";
import BurgerToggleIcon from "./BurgerToggleIcon";
import { varFadeIn } from "../../../components/Animate";
import { PAGES } from "../../../constants/pages";
import LogoutButton from "../../../components/Buttons/LogoutButton";
import { logout } from "../../../redux/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { isWalletBrowser } from "../../../utils/isMobile";
import logoSm from "../../../assets/icons/logo_sm.svg";

const trapezoidStyles = {
  content: "''",
  width: "11px",
  height: "100%",
  position: "absolute",
  top: 0,
  right: "-11px",
  backgroundImage: `url(${bgEffect})`,
  backgroundSize: "100px",
  clipPath: "polygon(-1px -1px,11px 11px,100% 100%,-1px 100%)"
};

const useStyles = (theme) => {
  return {
    navLink: {
      fontSize: "13px",
      position: "relative",
      cursor: "pointer",
      padding: "0 25px",
      lineHeight: "20px",
      color: theme.palette.primary.main,
      transition: "all .2s ease-in-out",
      WebkitTapHighlightColor: "transparent",
      outline: "0",
      textTransform: "uppercase",
      textDecoration: "none"
    },

    activeNavLink: {
      "& div": {
        position: "relative",

        "&::before": {
          content: "''",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "-5px",
          borderLeft: `1px solid ${theme.palette.primary.main}`,
          height: "17px",
          width: "1px"
        }
      }
    }
  };
};

const appBarSX = {
  position: "relative",
  width: "initial",
  mt: 1.5,
  backgroundImage: `url(${bgEffect})`,
  backgroundSize: "100px",
  backgroundColor: "initial",
  color: (theme) => theme.palette.primary.main,

  "&::after": {
    ...trapezoidStyles
  }
};

const fullScreenNavSX = {
  position: "fixed",
  paddingTop: "52px",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  zIndex: -1,
  width: "100vw",
  height: "100vh",
  left: 0,
  top: 0,
  minHeight: "100vh",
  background: "linear-gradient(90.38deg, #070911 -7.76%, rgba(0, 0, 0, 0.06) 85.27%)"
};

const MobileNavbar = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: (theme) => theme.zIndex.modal - 1,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "space-between"
      }}>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "flex-end"
        }}>
        <AppBar sx={appBarSX} position="static">
          <Container sx={{ ml: "0", p: { xxs: 0, xs: "0 24px" } }} maxWidth="xl">
            <Toolbar style={{ height: { xxs: 40, xs: 53 }, minHeight: "40px" }} disableGutters>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}>
                <BurgerToggleIcon onChange={() => setIsOpen(!isOpen)} open={isOpen} />

                <Box
                  onClick={() =>
                    isWalletBrowser()
                      ? window.location.assign("https://delysium.com/")
                      : window.open("https://delysium.com/", "_blank")
                  }
                  sx={{
                    background: `url(${logoSm})`,
                    backgroundSize: "100%",

                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    cursor: "pointer",
                    mr: "6px",
                    ml: 1.5,
                    width: 60,
                    height: 40,

                    display: "flex"
                  }}
                />

                <AnimatePresence>
                  {isOpen && (
                    <Box
                      component={motion.ul}
                      {...varFadeIn}
                      onClick={() => setIsOpen(false)}
                      sx={fullScreenNavSX}>
                      {PAGES.map(({ title, url = "#", sublist }, index) => (
                        <NavLink
                          exact={true}
                          to={url}
                          key={index}
                          style={{
                            ...classes.navLink,
                            ...classes.activeNavLink
                          }}
                          onClick={() => setIsOpen(false)}>
                          <Box my={2} display="flex" textTransform="uppercase" textAlign="left">
                            {title}
                          </Box>
                        </NavLink>
                      ))}
                    </Box>
                  )}
                </AnimatePresence>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        <Box
          sx={{
            flexGrow: 0,
            zIndex: (theme) => theme.zIndex.modal + 1,
            mr: { xxs: 2.5, xs: 5 },
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-evenly"
          }}>
          <LogoutButton handleClick={() => dispatch(logout(user?.token))} />
        </Box>
      </Box>
    </Box>
  );
};
export default MobileNavbar;
