import { Box, Button, Typography, useTheme } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";

import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import logoutBg from "../../assets/logout_bg.png";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import isMobileDevice from "../../utils/isMobile";
function LogoutButton({ handleClick }) {
  const [isHovering, setIsHovering] = useState(false);
  const theme = useTheme();
  const styles = getAugmentedStyle({
    color: theme.palette.warning.main,
    bl1: "0px"
  });
  return (
    <AnimatePresence>
      <Button
        onClick={handleClick}
        disableRipple
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onTouchStart={() => setIsHovering(true)}
        onTouchEnd={() => setIsHovering(false)}
        component={motion.div}
        initial={{ width: 32 }}
        animate={{
          width: isHovering && !isMobileDevice() ? 110 : 32,
          transition: { delay: 0, duration: 0.3 }
        }}
        style={{ ...styles }}
        sx={{
          p: 0,
          minWidth: 0,
          width: 32,
          height: 32,
          backgroundImage: `url(${logoutBg})`,
          backgroundSize: "100px",
          "&:hover": {
            background: `url(${logoutBg})`,
            backgroundSize: "100px"
          }
        }}
        data-augmented-ui="bl-clip border">
        <Box display="flex" alignItems="center">
          <LogoutIcon width={16} height={16} />

          {isHovering && !isMobileDevice() && (
            <Typography
              ml={1}
              fontSize={12}
              noWrap
              fontWeight={500}
              color={theme.palette.warning.main}>
              Log Out
            </Typography>
          )}
        </Box>
      </Button>
    </AnimatePresence>
    // </IconButton>
  );
}

export default LogoutButton;
